<template>
    <div class="bookingSummary">
        <div class="bookingSummaryMainDiv">
            <div class="bookingTitle">This is only a request to book</div>
            <div class="bookingTitleDetail">
                An email will be sent to you shortly with details of your request. <br>

                Our concierge team will be in touch within 24 hours to confirm your booking. <br>

                Any questions? <a class="bookingTitleDetailMailLink" href="mailto:hello@vuecollection.com"
                    target="_blank">hello@vuecollection.com</a>
            </div><br>
            <div class="bookingSummarySubDiv">
                <div class="bookingSummaryText">Booking Summary</div>
                <div class="bookingSummaryContentMainDiv">

                    <div class="bookingAmanzoe">{{ hotelname }}</div>
                    <div class="bookingAmanzoeDetail">{{ address }}</div>
                    <div class="bookingSummaryLineDiv"></div>

                    <div class="bookingDetailCellDiv">
                        <div class="bookingDetailCellImgDiv">
                            <img class="bookingDetailItemIcon" src="../assets/images/house_icon.png">
                        </div>
                        <div class="bookingDetailCellContentDiv">
                            <div class="bookingDetailItem1">Room</div>
                            <div class="bookingDetailItem2">{{ roomName }}</div>
                        </div>
                    </div>
                    <div class="bookingDetailCellDiv">
                        <div class="bookingDetailCellImgDiv">
                            <img class="bookingDetailItemIcon" src="../assets/images/calendar_icon.png">
                        </div>
                        <div class="bookingDetailCellContentDiv">
                            <div class="bookingDetailItem1">Dates</div>
                            <div class="bookingDetailItem2">{{ moment(Date.parse(fromDate)).utc().format('DD MMMM YYYY') }} -
                                {{ moment(Date.parse(toDate)).utc().format('DD MMMM YYYY') }} ({{ days }} nights)</div>
                        </div>
                    </div>
                    <div class="bookingDetailCellDiv">
                        <div class="bookingDetailCellImgDiv">
                            <img class="bookingDetailItemIcon" src="../assets/images/dollar_icon.png">
                        </div>
                        <div class="bookingDetailCellContentDiv">
                            <div class="bookingDetailItem1">Rate Offer</div>
                            <div class="bookingDetailItem2">{{ offer }}</div>
                        </div>
                    </div>
                    <div class="bookingDetailCellDiv">
                        <div class="bookingDetailCellImgDiv">
                            <img class="bookingDetailItemIcon" src="../assets/images/standard_icon.png">
                        </div>
                        <div class="bookingDetailCellContentDiv">
                            <div class="bookingDetailItem1">Guests</div>
                            <div class="bookingDetailItem2">
                                <div class="bookingDetailItemChild">
                                    <div v-for="item, index in guestsNames" :key="index">
                                        {{ item.text }}
                                    </div>
                                </div>
                                <div class="bookingDetailItemChild">
                                    <div v-for="item, index in guestsChildNames" :key="index">
                                        {{ item.text }} ({{ guestsChildAges[index] }} ages)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bookingDetailParentDiv">
                        <div class="bookingDetailHotelDetail1">SUMMARY ({{ currencyName }} currency):</div>
                        <div class="bookingDetailHotelDetailParentDiv">
                            <div class="bookingDetailHotelDetail2">Room total</div>
                            <div class="bookingDetailHotelDetail3">{{ currencySign }}{{ addZeroes(Math.round(subPrice * 100) /
                                100) }}</div>
                        </div>
                        <div class="bookingDetailLineDiv"></div>

                        <div class="bookingDetailHotelDetail4">Mandatory Service & Fees:</div>
                        <div class="bookingDetailHotelDetailParentDiv">
                            <div class="bookingDetailHotelDetail2">$6 Green Tax</div>
                            <div class="bookingDetailHotelDetail3">{{ currencySign }}{{ addZeroes(Math.round(tax * currency *
                                100) / 100) }}</div>
                        </div>
                        <div class="bookingDetailLineDiv"></div>

                        <div class="bookingDetailHotelDetailParentDiv">
                            <div class="bookingDetailHotelDetail2">GST & Service charge</div>
                            <div class="bookingDetailHotelDetail3">{{ currencySign }}{{ addZeroes(Math.round(gstService *
                                currency * 100) / 100) }}</div>
                        </div>
                        <div class="bookingDetailLineDiv"></div>

                        <div class="bookingDetailHotelDetailParentDiv">
                            <div class="bookingDetailHotelDetail2">Extra Adult Charge</div>
                            <div class="bookingDetailHotelDetail3">{{ currencySign }}{{ addZeroes(Math.round(extraAdult *
                                currency * 100) / 100) }}</div>
                        </div>
                        <div class="bookingDetailLineDiv"></div>

                        <div class="bookingDetailHotelDetailParentDiv">
                            <div class="bookingDetailHotelDetail2">Extra Child Charge</div>
                            <div class="bookingDetailHotelDetail3">{{ currencySign }}{{ addZeroes(Math.round(extraChild *
                                currency * 100) / 100) }}</div>
                        </div>
                        <div class="bookingDetailLineDiv"></div>

                        <div v-if="permissionLevel >= 4" class="bookingDetailHotelDetailParentDiv">
                            <div class="bookingDetailHotelDetail2">{{ selectedHotel != undefined &&
                                selectedHotel.hoteldatageneral != undefined && selectedHotel.hoteldatageneral.commission !=
                                undefined ? Math.floor(selectedHotel.hoteldatageneral.commission * 100) : 14 }}% Commission
                                (USD)</div>
                            <div class="bookingDetailHotelDetail3">{{ currencySign }}{{ addZeroes(Math.round(commission *
                                currency * 100) / 100) }}</div>
                        </div>
                        <div v-if="permissionLevel >= 4" class="bookingDetailLineDiv"></div>
                        <div class="bookingDetailAddOns">ADD-ON’S</div>
                        <div class="bookingDetailAddOnsCellDiv">
                            <div class="bookingDetailHotelDetail2">Transfer</div>
                            <div class="bookingDetailHotelDetail3">{{ currencySign }}{{ addZeroes(Math.round(transferTotalPrice
                                * currency * 100) / 100) }}</div>
                        </div>
                        <div class="bookingDetailLineDiv"></div>
                        <div class="bookingDetailAddOnsCellDiv">
                            <div class="bookingDetailHotelDetail2">Meal Plan</div>
                            <div class="bookingDetailHotelDetail3">{{ currencySign }}{{ addZeroes(Math.round(mealplanTotalPrice
                                * currency * 100) / 100) }}</div>
                        </div>
                        <div class="bookingDetailLineDiv"></div>
                        <div class="bookingDetailAddOnsCellDiv">
                            <div class="bookingDetailHotelDetail2">Festive Plan</div>
                            <div class="bookingDetailHotelDetail3">{{ currencySign }}{{ addZeroes(Math.round(festiveTotalPrice
                                * currency * 100) / 100) }}</div>
                        </div>
                        <div class="bookingDetailLineDiv"></div>
                        <div class="bookingHotelDetail5">Final Total: {{ currencySign }}{{ addZeroes(Math.round(grossTotalPrice
                            * currency * 100) / 100) }}</div>
                    </div>
                    <div class="bookingDetailBottomDiv">
                        <div class="bookingPackageTitle">Payment & cancellation</div>
                        <div v-show="termsCondition != ''" class="bookingTermsTextDiv">
                            <div class="bookingDetailItem4">
                                {{ termsCondition }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
 
<!-- <iframe style="max-width:100%;" width="820px" height="380px" frameborder="0" align="middle" src="https://www.ref-r.com/campaign_user/p?brandid=67653&campaignid=33855&bid_e=5FEC791452A7F3677618B663E2BC62C7&t=420&email=&fname="></iframe> -->

<script>
import moment from 'moment';
import { api } from '@/helpers/Helpers';
export default {

    data() {
        return {
            affiliateEmail: 'example@gmail.com',
            affiliateFname: 'Mike',
            bookingReqId: '',

            permissionLevel: 1,

            hotelname: '',
            address: '',
            guestsNames: [],
            guestsChildNames: [],
            guestsChildAges: [],
            offer: '',

            roomName: '',
            fromDate: null,
            toDate: null,
            days: 0,
            guests: '',
            adults: 0,
            childs: 0,
            include: '',
            subPrice: 0,
            tax: 0,
            gstService: 0,
            extraAdult: 0,
            extraChild: 0,
            commission: 0,

            transferTotalPrice: 0,
            mealplanTotalPrice: 0,
            festiveTotalPrice: 0,

            grossTotalPrice: 0,

            termsCondition: '',
            termsConditionShort: '',

            isTermsConditionShort: true,


            currency: 1.0,
            currencyName: 'USD',
            currencySign: '$',

            selectedHotel: undefined,
        };
    },
    computed: {
        renderAffiliateLink() {
            const baseUrl = 'https://www.ref-r.com/campaign_user/p';
            const brandId = 67653; // Replace with your brand ID
            const campaignId = 33855; // Replace with your campaign ID

            const affiliateUrl = `${baseUrl}?brandid=${brandId}&campaignid=${campaignId}&bid_e=5FEC791452A7F3677618B663E2BC62C7&t=420&email=${this.affiliateEmail}&fname=${this.affiliateFname}`;

            return `<iframe style="max-width:100%;" width="820px" height="380px" frameborder="0" align="middle" src="${affiliateUrl}"></iframe>`;
        }
    },
    created() {
        this.currency = this.$store.state.selectedCurrency.rate;
        this.currencyName = this.$store.state.selectedCurrency.name;
        this.currencySign = this.$store.state.selectedCurrency.sign;

        if (this.$store.state.loginUser !== null) {

            const loginType = this.$store.state.loginUser.loginType;
            this.permissionLevel = this.$store.state.loginUser[loginType].permissionLevel;

        }
    },
    mounted() {
        if (this.$route.query.bookingReqId != undefined)
            this.bookingReqId = this.$route.query.bookingReqId;
        this.getBookingRequestInfo(this.bookingReqId);
        window.scrollTo(0, 0);

        // Tracking script integration
        const script = document.createElement('script');
        script.innerHTML = `
            ir('track', {
                orderID: ${this.bookingReqId},
                event: 'sale',
                fname: ${this.affiliateFname},
                email: ${this.affiliateEmail},
                mobile: '9717XXXXXX',
                purchaseValue: ${this.grossTotalPrice},
                order_custom_val: ''
            });
        `;
        document.head.appendChild(script);
        },

    methods: {
        moment,
        addZeroes(num) {
            let textNum = num.toString();
            let decimalResult = Number(textNum).toFixed(Math.max(textNum.split('.')[1]?.length, 2) || 2);
            let thousand = decimalResult.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return thousand;
        },
        getBookingRequestInfo: async function (id) {
            let result = await api.getBookingRequestInfo(id);
            if (result != undefined) {
                this.selectedHotel = await api.getHotelById(result.hotelid);
                var guestsNames = [];
                for (let j = 0; j < result.guestnames.length; j++) {
                    let aInfo = {
                        text: result.guestnames[j].firstname + ' ' + result.guestnames[j].lastname
                    }
                    guestsNames.push(aInfo);
                }
                var guestsChildAges = [];
                if (result.totalchildage != undefined) {
                    guestsChildAges = result.totalchildage;
                }
                var guestsChildNames = [];
                for (let j = 0; j < result.guestchildnames.length; j++) {
                    let aInfo = {
                        text: result.guestchildnames[j].firstname + ' ' + result.guestchildnames[j].lastname
                    }
                    guestsChildNames.push(aInfo);
                }
                this.hotelname = result.hotelname;
                if (this.selectedHotel != undefined)
                    this.hotelname = this.selectedHotel.hotelname;
                this.address = result.address;
                this.guestsNames = guestsNames;
                this.guestsChildNames = guestsChildNames;
                this.guestsChildAges = guestsChildAges;
                this.offer = result.datalist.map(e => e.promotionname).join(", ");

                this.roomName = result.datalist.map(e => e.roomname).join(", ");
                this.fromDate = result.fromDate;
                this.toDate = result.toDate;
                this.guests = result.totaladultcount + " adults ";
                if (result.totalchildcount > 0)
                    this.guests += 'and ' + result.totalchildcount + " childrens"
                this.adults = result.totaladultcount;
                this.childs = result.totalchildcount;
                this.include = result.includeenhance.join(', ');
                this.subPrice = result.grossTotalPrice - result.gstService - result.taxat - result.extraAdult - 
                result.extraChild - result.transferTotalPrice - result.mealplanTotalPrice - result.festiveTotalPrice;
                this.tax = result.taxat;
                this.gstService = result.gstService != undefined ? result.gstService : 0;
                this.extraAdult = result.extraAdult != undefined ? result.extraAdult : 0;
                this.extraChild = result.extraChild != undefined ? result.extraChild : 0;
                this.commission = result.commission != undefined ? result.commission : 0;

                this.transferTotalPrice = result.transferTotalPrice != undefined ? result.transferTotalPrice : 0;
                this.mealplanTotalPrice = result.mealplanTotalPrice != undefined ? result.mealplanTotalPrice : 0;
                this.festiveTotalPrice = result.festiveTotalPrice != undefined ? result.festiveTotalPrice : 0;

                this.grossTotalPrice = result.grossTotalPrice;

                // this.inclusiontext = result.inclusiontext;
                this.termsCondition = result.termsconditions;

                // this.inclusionTextShort = result.inclusiontext;
                this.termsConditionShort = ''
                this.termsConditionShort = result.termsconditions;
                if (this.termsConditionShort != undefined && this.termsConditionShort.length > 150)
                    this.termsConditionShort = this.termsConditionShort.substring(0, 150) + "......";

                const diffTime = Math.abs(Date.parse(this.toDate) - Date.parse(this.fromDate));
                this.days = Math.round(diffTime / (1000 * 60 * 60 * 24));
            }
        },
        removeBookingRequest: async function () {
            // eslint-disable-next-line no-unused-vars
            let result = await api.removeBookingRequest(this.bookingReqId);
            this.$message.success('Your booking was canceled successfully!');
            setTimeout(() => {
                this.$router.push({ path: '/' });
            }, 2000);
        }
    },
}
    // ir('track', { 
    //     orderID: 'ABXHXXXXX', // Order ID 
    //     event: 'sale', 
    //     fname: 'Mike', // Order Name 
    //     email: 'example@gmail.com', // Order Email 
    //     mobile: '9717XXXXXX', // Order Mobile 
    //     purchaseValue: '1000', // Order Amount 
    //     order_custom_val: '' // Optional (Any Custom Value)
    // }); 
</script>
<!-- <style scoped>
.bookingRemoveBtn{
    position:absolute;
    right:30px;
    top:0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    text-align: right;
    text-decoration-line: underline;
    color: #D00B0B;
    cursor: pointer;
}
</style> -->