<template>
  <lazy-component class="home" style="min-height:85vh;overflow-x: hidden;background-color:#FAFFFF;">
    
    <div :key="updateHomeImages" style="position:relative;min-height: 80vh;">
      <!-- <img Vue logo src="../assets/images/home_main.png" height="auto" width="100%"/> -->
    
      <!-- <carousel v-if="mainImageInfo != undefined && mainImageInfo.length > 0"
        style="min-height: 80vh;"
        v-bind:class="[$mq === 'lg' || $mq === 'md' ? 'homeMainImageDiv' : 'homeMainImageDivMobile']"
        :autoplay="true"
        :nav="false"
        :items="1"
        :loop="true"
      > -->
        <!-- <div style="position:relative" v-for="info, n in mainImageInfo" :key="n" > -->
            <img class="slideHomeMain" style="cursor:pointer"  :src="mainImageInfo[0]" >
        <!-- </div> -->
      <!-- </carousel>
      
      <div v-else style="min-height: 80vh;display: flex;">
        <img class="homeLoadingGifDiv" style="margin:auto"
        :src="'../images/logo.gif'" alt="" width="100px" height="100px" loop=infinite>
      </div> -->

      <div class="homeMainTitleDiv">
        <div class="homeMainTitleSubDiv">
          <!-- <div class="homeMainTitle">
            <div  class="homeMainSubTitle">Collection Luxury Resorts. Exceptional Rates</div>
            <div  class="homeMainSubTitle">Concierge Service. Zero Hidden Fees</div>
          </div>           -->
          <!-- <div class="homeMainTitleDetail"><br>Visited, reviewed and approved by Travel Experts.</div>  -->
          
          <div class="homeMainSubTextDiv">
            <div class="homeVerticalTextDiv">
              <div class="flexDiv">
                <div class="subSecond">Maldives Luxury Resorts.</div>
                <div class="subSecond"> Exceptional Rates.</div>
              </div>
              <div class="flexDiv">
                <div class="subSecond">Concierge Service.</div>
                <div class="subSecond"> Zero Hidden Fees.</div>
              </div>
            </div>
          </div> 

        </div>
      </div>
    </div>
    
    <!-- <div class="MobileReview" @click="clickReview">
      <div class="reviews-io-rating-bar-widget"
        widget-id="dqsf9kr83KG02VWa"
        data-store-name="vue-maldives"
        lang="en"
      ></div>
    </div> -->
    <div class="homeSearchTotalDiv">
      <div class="homeSearchMainDiv">
        <div class="homeSearchMain">       

          <div class="homeSearchMainSub">
            <div class="homeSearchMainSub4">
              <div class="dateSelectHotelDiv">
                <div style="position:relative;">
                  <!-- <div class="homePerfectResortText">Find Your Perfect Resort</div> -->
                  <a-select v-model="selectedHotelList" 
                    ref="pickerResorts"
                    :open="pickerResortsOpen"
                    :filter-option="filterOption" @change="handleFilterChange" 
                    @blur="onBlurHotel" @dropdownVisibleChange="dropdownHotelChange" style="width:100%" mode="tags" placeholder="View All or Resort Name">
                    <a-select-option v-for="_item in allHotels" :key="_item._id" :value="_item._id">
                      <div style="display:flex">
                        <span class="dateSelectHotelCell font-new">{{ _item.hotelname}}</span>
                      </div>
                    </a-select-option>
                  </a-select>
                  <div class="dateSelectHotelIconDiv">
                    <a-icon class="dateSelectHotelIconCell" type="down" :style="isResortDropdown ? { fontSize: '20px', color: '#787878', 'transform':'rotate(180deg)'}:{ fontSize: '20px', color: '#787878', 'transform':'rotate(0deg)'}" />
                  </div>
                </div>
                <!-- <div class="homeSelectText" @click="openHotelSelector">Select</div> -->
              </div>
              <div class="homeSearchMainLine"></div>
              <div class="dateSearchDiv">
                <div class="dateSearchDivMain">
                  <div class="dateSearchSubDiv">

                    <date-range-picker
                      class="dataRangePicker"
                      ref="pickerMain"
                      :opens="'center'"
                      :locale-data="{ firstDay: 0, format: 'dd-mm-yyyy HH:mm:ss' }"
                      :minDate="minDate" :maxDate="maxDate"
                      :singleDatePicker="false"
                      :timePicker="false"
                      :timePicker24Hour="false"
                      :showWeekNumbers="false"
                      :showDropdowns="false"
                      :autoApply="true"
                      :ranges="false"
                      :closeOnEsc="false"
                      v-model="dateRange"
                      :date-format="dateFormat"
                      @update="updateValues"
                      @start-selection="startSelection"
                      @finish-selection="finishSelection"
                      @toggle="dateToggle"
                      :linkedCalendars="true"
                    >
                    <!--  style="min-width: 350px;" -->
                      <template v-slot:input="picker">
                        <div @click="OnClickPicker">
                          {{ `${picker.startDate != null? moment(picker.startDate).utc().format("ddd MMM DD YYYY") : 'Check in '} - 
                          ${picker.endDate != null? moment(picker.endDate).utc().format("ddd MMM DD YYYY") : 'Check out '}`}}

                        </div>
                      </template>
                    </date-range-picker>

                  </div>
                  <div class="dateSelectHotelIconDiv">
                    <a-icon class="dateSelectHotelIconCell" type="down" :style="isDateDropdown ? { fontSize: '20px', color: '#787878', 'transform':'rotate(180deg)'}:{ fontSize: '20px', color: '#787878', 'transform':'rotate(0deg)'}" />
                  </div>
                </div>
                
                <!-- <div class="homeSelectText" @click="openDateSelector(true)">Select</div> -->
                
              </div>
              <div class="homeSearchMainLine"></div>
              <div class="homeCountPickerDiv">
                
                <div class="homeCountPickerSubDiv">
                  <!-- <a-popover v-model="visibleRoomList" v-if="searchRoomInfo.length > 0" title="Room List" trigger="click" placement="bottom">
                    <template slot="content">
                      <div style="gap: 5px;display: grid;"> 
                        <div v-for="(item, index) in searchRoomInfo" :key="index" style="border: 1px solid #EEEEEE;padding:3px;padding-left: 10px;padding-right: 10px;display: flex;">
                          <span @click="editSearchRoomInfo(index)" style="margin-top:auto;cursor:pointer;margin-bottom:auto;width:100%;color:#787878;margin-right: 10px;">Room {{index + 1}}: {{item.searchAdultCount}} adults, {{item.searchChildCount}} children</span> 
                          <a-icon style="margin: auto;" type="close" @click="removeSearchRoomInfo(index)" class="minusRowBtn" :size="'small'"></a-icon>
                        </div>
                      </div>
                    </template> --> 
                    <div class="homeCountPickerSubDiv1" @click="showSearchCountPicker(true)">
                      <!-- <a-icon style="margin-top:auto;margin-bottom:auto;margin-left:10px;color:#787878" type="user" /> -->
                      <span class="font-new homeCountPickerSubText font-new">{{searchRoomInfo.length}} rooms, {{searchRoomInfo.reduce((accumulator, current) => accumulator + current.searchAdultCount, 0)}} adults, {{searchRoomInfo.reduce((accumulator, current) => accumulator + current.searchChildCount, 0)}} children</span> 
                    </div>
                  <!-- </a-popover>
                  <div v-else style="display:flex;cursor:pointer;width:100%">
                    <a-icon style="margin-top:auto;margin-bottom:auto;margin-left:10px;color:#787878" type="user" />
                    <span style="margin-top:auto;margin-bottom:auto;width:100%;margin-left:10px;color:#787878">0 rooms, 0 adults, 0 children</span> 
                  </div> -->
                  <!-- <div v-if="false" style="margin-top:auto;margin-bottom:auto;margin-right: 0;width: 250px;cursor:pointer;color:var(--title-color)" @click="showSearchCountPicker(true)">
                    + Add Another Room
                  </div> -->
                  
                  <div class="dateSelectHotelIconDiv">
                    <a-icon class="dateSelectHotelIconCell" type="down" :style="isCountDropdown ? { fontSize: '20px', color: '#787878', 'transform':'rotate(180deg)'}:{ fontSize: '20px', color: '#787878', 'transform':'rotate(0deg)'}" />
                  </div>
                </div>
                <!-- <div class="homeSelectText" @click="showSearchCountPicker(true)">Select</div> -->
              </div>
              <div class="homeSearchMainLine"></div>

              <!-- <a-button class="homeSearchBtn" type="primary" @click="gotoHotelSearchClick(true)">
                <div style="display:flex">
                  <div class="homeSearchBtnText">SEARCH</div>
                  <img class="homeSearchBtnIcon" src="../assets/images/icon_search.png" alt="" width="29px">
                </div>
              </a-button> -->
              
              <div class="homeSearchBtnMobile" @click="gotoHotelSearchClick(true)">
                <div style="display:flex;margin:auto">
                  <div class="homeSearchBtnText font-new">SEARCH</div>
                  <img class="homeSearchBtnIcon" src="../assets/images/icon_search.png" alt="" width="29px">
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="homePerfectResortDiv" v-if="false">
            <div class="homePerfectResortText" @click="clickPerfectResortBtn">Find Your Perfect Resort</div>
            
            <img class="homePerfectResortHandImg" src="../assets/images/icon_finger.png" alt="" width="36px">
          </div> -->
          <!-- <div class="homeLineShadow"></div> -->
          <a-modal
            v-model="visibleSearchCountPicker"
            title=""
            :footer="null"
          >
            <person-count-selector-multi v-if="visibleSearchCountPicker"
              :arr="searchRoomInfo" @getCountInfos="getCountInfos"></person-count-selector-multi>
          </a-modal>
          <a-modal
            v-model="visibleSearchCountPickerLast"
            title=""
            :footer="null"
          >
            <person-count-selector-multi v-if="visibleSearchCountPickerLast" :isSearchClick="true"
              :arr="searchRoomInfo" @getCountInfos="getCountInfosSearch"></person-count-selector-multi>
          </a-modal>
        </div>
      </div>

    </div>
    
    <!-- <div class="WebReview" @click="clickReview">
      <div class="reviews-io-rating-bar-widget"
        widget-id="dqsf9kr83KG02VWa"
        data-store-name="vue-maldives"
        lang="en"
      ></div> 
    </div>-->
    <!-- <div style="display: flex;margin-top: 30px;">
      <div id="reviewsio-carousel-widget" style="margin: auto;">
      </div>
    </div> -->

    <!-- <div class="homeSubTitle">ABOUT MALDIVES</div> -->
    <div class="homeAboutTextMainDiv">
      
      <div class="homeAboutTextSubDiv">
        
        <a-list :grid="{ gutter: 16, xs: 1, sm: 1, md: 3, lg: 3, xl: 3, xxl: 3 }" :data-source="aboutInfo">
          <a-list-item class="homeAboutImageView" slot="renderItem" slot-scope="item, iItem">

            <div class="homeAboutItemMainDiv">
              <vue-flip :active-hover="$mq === 'lg' || $mq === 'md'" :active-click="$mq != 'lg' && $mq != 'md'" class="homeAboutItemFlipDiv" width="" height="">
                <div slot="front">
                  <div class="homeAboutItemFlipFrontDiv">
                    <img class="homeAboutItemFlipFrontImg" :src="item.icon != '' && item.icon != undefined? `${item.icon}` : ''" alt="">
                  </div>
                  <div class="homeAboutItemFlipFrontShadow"></div>
                  <div class="homeAboutTitle">
                    {{item.name}}
                  </div>
                </div>
                <div slot="back">
                  <div class="homeAboutItemFlipBack">
                    <!-- box-shadow: inset 0px 0px 20px rgba(0,0,0,0.9); -->
                    <div class="homeAboutItemFlipBackDiv">
                      <img class="homeAboutItemFlipBackImg" :src="item.icon != '' && item.icon != undefined? `${item.icon}` : ''">
                    </div>
                    <div class="homeAboutItemFlipBackMainDiv">
                      <div class="homeAboutItemFlipBackSubDiv">
                        <div class="homeAboutItemFlipBackName">
                          {{item.name}}    
                        </div>
                        <div class="homeAboutItemFlipBackDetail">
                          {{item.detail}}
                        </div>
                        <div class="homeAboutItemFlipBackSubDiv1">
                          
                          <div class="homeViewBtn" @click="gotoAboutItem(item.link, iItem)" >
                            VIEW
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </vue-flip>
            </div>
          </a-list-item>
        </a-list>
      </div>
    </div>
    <div v-if="false" style="display:flex;margin-left: auto;margin-right: auto;max-width:1100px;margin-top:20px;padding-left:10px;padding-right:10px">
      <div style="display:flex;margin-left: auto;margin-right: auto;background:var(--primary-sub-title-color);padding:5px;padding-left:20px;padding-right:20px">
        <div v-responsive.lg.xl.md class="homeAboutSafetyText">
          <img style="margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:4px" src="../assets/images/safety.png" alt="" width=23px height=26px>
          <span id="bannertext1"></span>
        </div>
        <div v-responsive.sm.xs class="homeAboutSafetyText">
          <img style="margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:4px" src="../assets/images/safety.png" alt="" width=15px height=18px>
          <span id="bannertext2" style="font-size: 10px;line-height: 1;"></span>
        </div>
      </div>
    </div>
    <div class="homeBestOfferDiv">
      <div class="homeBestOfferTitle">Best Offers</div>
      <div class="homeBestOfferDetail">
        <div class="homeBestOfferDetailDiv">
          <a-list v-if="!loadingBestOffers && bestList.length > 0" item-layout="horizontal" :data-source="bestList" :split=false>
            <a-list-item slot="renderItem" slot-scope="item">
                <view-item class="homeBestOfferDetailItemDiv" :cellData="item" >
                </view-item>
            </a-list-item>
          </a-list>
          <div v-else-if="!loadingBestOffers && bestList.length <= 0">  
            
            <div style="text-align: center;margin-top: 50px;">
              <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fillRule="evenodd"><ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse><g fillRule="nonzero" stroke="#D9D9D9"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path></g></g></svg>
            </div>
            <div class="resortEmptyDiv">
              Apologies as we do not have a property that matches your criteria.
              Please select another option or contact our Concierge via chat to assist you further.
            </div>
          </div>
          <div v-else class="homeLoadingDiv">
            <img class="homeLoadingGifDiv"
            :src="'../images/logo.gif'" alt="" width="100px" height="100px" loop=infinite>
          </div>
        </div>
      </div>
    </div>

    <div class="homeNewsDiv">
      <div class="homeNewsMain">
        <div class="homeNewsMainDiv1">
          
          <div class="homeNewsSubDiv1">
            <img class="homeNewsSubImg" :src="aboutNewsInfo.icon != '' && aboutNewsInfo.icon != undefined? `${aboutNewsInfo.icon}` : ''" alt="">
          </div>
        </div>
        <div class="homeNewsMainDiv2">
          <div class="homeSubTitle font-new" style="text-align: left;">News</div>
          <div>
            <div class="homeNewsSubDiv2 font-new">
              {{aboutNewsInfo.detail}}
            </div>
          </div>
          
          <div class="homeViewMore font-new" @click="gotoAboutNewsItem()" >
            View More
          </div>
        </div>
      </div>
    </div>

    <contactus-viewer></contactus-viewer>
    <instagram-viewer style="padding-bottom:20px;"></instagram-viewer>    

    <a-modal class="AboutDashboardDiv"
      :width="$mq === 'lg' || $mq === 'md' ? '900px' : ''"
      v-model="visibleBestTimeToGo"
      title=""
      :footer="null"
      :dialog-style="{'align-items': 'center', 'top': '10px', 'max-width': '1200px', 'height': '100%'}"
    >
      <div style="margin:auto;">
          <besttime-togo @close="() => {visibleBestTimeToGo = false}">            
          </besttime-togo>
      </div>
    </a-modal>
    <a-modal class="AboutDashboardDiv"
      :width="$mq === 'lg' || $mq === 'md' ? '900px' : ''"
      v-model="visibleHowToGetHere"
      title=""
      :footer="null"
      :dialog-style="{'align-items': 'center', 'top': '10px', 'max-width': '1200px', 'height': '100%'}"
    >
      <div style="margin:auto;">
          <howto-gethere @close="() => {visibleHowToGetHere = false}"></howto-gethere>
      </div>
    </a-modal>
    <a-modal class="AboutDashboardDiv"
      :width="$mq === 'lg' || $mq === 'md' ? '900px' : ''"
      v-model="visibleNewsView"
      title=""
      :footer="null"
      :dialog-style="{'align-items': 'center', 'top': '10px', 'max-width': '1200px', 'height': '100%'}"
    >
      <div style="margin:auto;">
          <news-view @close="() => {visibleNewsView = false}"></news-view>
      </div>
    </a-modal>
    <a-modal class="AboutDashboardDiv"
      :width="$mq === 'lg' || $mq === 'md' ? '900px' : ''"
      v-model="visibleBeforeYouGo"
      title=""
      :footer="null"
      :dialog-style="{'align-items': 'center', 'top': '10px', 'max-width': '1200px', 'height': '100%'}"
    >
      <div style="margin:auto;">
          <before-yougo @close="() => {visibleBeforeYouGo = false}"></before-yougo>
      </div>
    </a-modal>
  </lazy-component>
</template>

<script>
// @ is an alias to /src
import VueFlip from 'vue-flip';
import ViewItem from './ListItems/home-offer-item.vue'
import { api } from '../helpers/Helpers';
// import carousel from 'vue-owl-carousel'
// import PersonCountSelector from './SearchDialogs/PersonCountSelector.vue';
// import { common } from '../helpers/Common';
// import { bus } from '../main'
import PersonCountSelectorMulti from './SearchDialogs/PersonCountSelectorMulti.vue';
import moment from 'moment';

import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import BesttimeTogo from './ListItems/besttime-togo.vue'
import HowtoGethere from './ListItems/howto-gethere.vue'
import NewsView from './ListItems/news-view.vue'
import BeforeYougo from './ListItems/before-yougo.vue'
import ContactusViewer from './ListItems/contactus-viewer.vue';
import InstagramViewer from './ListItems/instagram-viewer.vue';
const bestList = [
];
//const instaAccessToken = "IGQVJYMVRKS1ZAyNVRZAZAjJvemt3QlBOak81WXA2RlhrVU5mbHZAXeTR1Um83dkpLamRMdlJLTC1kZAkxOaTlTdnhiQm9Nc2pKWVA5RXNNMjlDNUpNWkZAodFlheGJlenczZAUpfZAU1tamMyQmVkemhQN0o1LQZDZD";
// const instaAccessToken = "IGQVJWTGE4Umh3aGREVXpmNzFVcG9odzQxRFRzQzk4cHNyckdQUDEyd2NJSDRybGI2Q0xfUDBMREpra21xRlV3YlZANd1M3SVpPNWllYmFPWVNrbjFqeHNwdWprTUItUnZAPSWpSOHUzNHBzcWJ2enhYNgZDZD";
//const instaAccessToken = "IGQVJXbXR2V2tKNjZAuZADdSMHFKX2pMUEU2cU94V2NCWkN1YWhfckRsbzVqOFdKOFN0ZAXl4N1dTRHg1RXg0bW1ielc4ZA1hNbXZAfLW4xSE00NGM5VnpwWHEwTkp2eVJIWmdWT2RxelJoSkQtbWlPMEdNZAAZDZD";
export default {
  name: 'Home',
  
  metaInfo: {
    // Children can override the title.
    title: 'VUE Collection',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site

    titleTemplate: '%s',
    
    //titleTemplate: '%s ← My maldives',
    // Define meta tags here.
    meta: [
      // {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      // {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'Discover unparalleled luxury and exclusive offers with VUE Collection Collection. Enjoy the best rates on luxury resorts in the Collection, handpicked for discerning travelers. Book your dream holiday today.'},


    ],
 
    link: [
      {rel: 'canonical', href: 'https://vuemaldives.com'}
    ]
  },

  data() {
    return {
        allHotels:[],
        selectedHotelList:[],
        bestList,
        
        mainImageInfo:[
          // '../images/home_main1.png', 
        ],
        updateHomeImages:false,
        
        aboutInfo:[
          {
            detail: "",
            icon: "",
            key: 0,
            link: "",
            name: "",
            _id: "",
          },
          {
            detail: "",
            icon: "",
            key: 0,
            link: "",
            name: "",
            _id: "",
          },
          {
            detail: "",
            icon: "",
            key: 0,
            link: "",
            name: "",
            _id: "",
          }
        ],
        aboutNewsInfo:
          {
            detail: "",
            icon: "",
            key: 0,
            link: "",
            name: "",
            _id: "",
          },
        bannerText:'',

        
        visibleSearchCountPicker:false,
        visibleSearchCountPickerLast:false,

        searchRoomInfo: [],
        searchRoomInfoID: 0,        

        searchStartDate:null,
        searchEndDate:null,
        visibleRoomList:false,
        visibleRoomListMobile:false,

        visibleBestTimeToGo:false,
        visibleHowToGetHere:false,
        visibleNewsView:false,
        visibleBeforeYouGo:false,
        pickerResortsOpen:false,

        
        // dateFormat : (val) => {
        //   return moment(val).utc().format('ddd MMM DD YYYY')// HH:mm:ss')
        // },
        dateRange:{
          startDate: null,
          endDate: null
        },
        selectedStartDate: null,
        selectedEndDate: null,

        minDate:new Date(),
        maxDate:null,

        
        isResortDropdown:false,
        isDateDropdown:false,
        isCountDropdown:false,

        showSearchFilterMobile:false,

        loadingBestOffers:false,

    };
  },
  components: {
    ViewItem,
    // carousel,
    "vue-flip": VueFlip,
    // PersonCountSelector,
    PersonCountSelectorMulti,
    DateRangePicker,
    BesttimeTogo,
    HowtoGethere,
    NewsView,
    BeforeYougo,
    ContactusViewer,
    InstagramViewer
},
  mounted(){
      // this.handleGetInstagram();
      let Script = document.createElement("script");
      Script.setAttribute("src", "https://widget.reviews.io/modern-widgets/rating-bar.js");
      document.head.appendChild(Script);
    this.initDate();
  },
  methods: {
      moment,
      initDate:async function(){
        
        this.selectedStartDate = new Date();

        await this.getBackgroundImages();
        await this.getAboutInfo();
        await this.getBestHotels();
        await this.getAllHotelList();
      },
      updateValues(aDate){
        this.searchStartDate = moment.utc(aDate.startDate).startOf('day').startOf('day').valueOf();
        this.searchEndDate = moment.utc(aDate.endDate).startOf('day').startOf('day').valueOf();
      },
      startSelection(aDate){
        this.selectedStartDate = aDate;
      },
      finishSelection(aDate){
        this.selectedEndDate = aDate;
      },
      dateToggle(dateToggle){
        
        this.selectedStartDate = null;
        this.selectedEndDate = null;
        if (!this.isDateDropdown && !dateToggle){
          this.$refs.pickerMain.togglePicker(true);
          this.isDateDropdown = true;
        }
        else{
          this.isDateDropdown = dateToggle;
        }
      },
      OnClickPicker(){
      },
      openHotelSelector(){
        this.$refs.pickerResorts.blur();
        this.pickerResortsOpen = true;  
        this.isResortDropdown = true;  
        setTimeout(() => {
          this.$refs.pickerResorts.focus();
        }, 50);
      },
      openDateSelector(isShow){
        this.$refs.pickerMain.togglePicker(isShow);
      },
      dateFormat (classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() < this.selectedStartDate
        }
        return classes
      },
      clickSearchFilterMobile(){
        this.showSearchFilterMobile = !this.showSearchFilterMobile;
      },
      // toggleValues(e){
      // },
      // startValues(e){
      // },
      // finishValues(e){
      // },
      
			// gotoContact(){
			// 	this.$router.push({ path: '/contact/'})
			// },
      filterOption(input, option) {
          const hotelInfo = this.allHotels.filter(obj => obj._id == option.key);
          if (hotelInfo.length > 0){
            return hotelInfo[0].hotelname.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return false;
      },
      
      onBlurHotel() {
        for (let i = 0; i < this.selectedHotelList.length; i++){
          const hotelInfo = this.allHotels.filter(obj => obj._id == this.selectedHotelList[i]);
          if (hotelInfo.length <= 0){
            this.selectedHotelList.splice(i, 1);
          }

        }
      }, 
      dropdownHotelChange(_isDropdown){
        this.isResortDropdown = _isDropdown;
        this.pickerResortsOpen = _isDropdown;
      },
      
      getAboutInfo: async function() {
          const res = await api.getAboutInfo();
          this.getAboutInfoResult(res);
      },
      getAboutInfoResult(res){
          this.aboutInfo = []
          if (res.length > 0){
            var _aboutInfo = res[0].datalist;
            if (_aboutInfo.length > 0){
              this.aboutNewsInfo = _aboutInfo[_aboutInfo.length - 1];
              this.aboutInfo = _aboutInfo.slice(0, _aboutInfo.length - 1);
            }
            // this.bannerText = res[0].banner.replace('CLICK HERE','<span style="color:red;">CLICK HERE</span>');
            // const banner = document.querySelector('#bannertext1');
            // banner.innerHTML = res[0].banner.replace('CLICK HERE','<span style="color:red;">CLICK HERE</span>')
            // const banner2 = document.querySelector('#bannertext2');
            // banner2.innerHTML = res[0].banner.replace('CLICK HERE','<span style="color:red;">CLICK HERE</span>')
          }
      },
      gotoAboutItem(_url, _index){
        // window.open(_url,"_blank");
        if (_index == 0){
          this.visibleBestTimeToGo = true;
        }
        else if (_index == 1){
          this.visibleHowToGetHere = true;
        }
        // else if (_index == 2){
        //   this.visibleNewsView = true;
        // }
        else if (_index == 2){
          this.visibleBeforeYouGo = true;
        }

      },
      gotoAboutNewsItem(){
        this.visibleNewsView = true;

      },

      getBestHotels:async function() {
        this.loadingBestOffers = true;
        const res = await api.getBestHotelInfoAll();
        this.getBestHotelsResult(res);
        
        this.loadingBestOffers = false;
      },
      getBestHotelsResult(res){
          let bestListDetail = res.hotelInfo;
          this.bestList = res.bestInfo;
          
          this.bestList.sort(function(a,b) {
              let aValue = a['shownewprice'];
              let bValue = b['shownewprice'];
              return (aValue > bValue) ? 1 : ((bValue > aValue) ? -1 : 0);
            } 
          );
          
          for(let i = 0; i < this.bestList.length; i++){
            const _info = bestListDetail.filter(obj => obj._id == this.bestList[i].hotelid);
            // let _temp = this.bestList[i];
            if (_info.length > 0)
              this.bestList[i]['info'] = _info[0];
          }
      },

      gotoHotelSearchClick(isShow){
        
        if (this.selectedHotelList.length <= 0 && (this.searchStartDate == null || this.searchEndDate == null || this.searchStartDate == this.searchEndDate)){
          // this.$message.error('Please select date range.');
          
          let routeData = this.$router.resolve({ path: '/resorts/'});
          window.open(routeData.href, '_self');
          return;
        }
        
        if (this.searchRoomInfo.length <= 0){
          this.visibleSearchCountPickerLast = isShow;
          if (isShow)
            this.searchRoomInfoID = this.searchRoomInfo.length;
          return;
        }
        this.gotoHotelSearch();
      },
      getCountInfosSearch(_infos){
        this.searchRoomInfo = _infos
        this.visibleSearchCountPickerLast = false;
        this.gotoHotelSearch();
      },

      clickReview(){
        window.open("https://www.reviews.io/company-reviews/store/vue-maldives", '_blank');
      },

      gotoHotelSearch(){
        if (this.searchStartDate == null || this.searchEndDate == null || this.searchStartDate == this.searchEndDate){
          this.$message.error('Please select date range.');
          return;
        }
        if (this.searchRoomInfo.length <= 0){
          this.$message.error('Please enter search information.');
          return;
        }
        if (this.searchRoomInfo.length <= 0){
          this.$message.error('Please enter search information.');
          return;
        }
        
        // if (!common.getLoginState(this.$store)){
        //     this.$message.error('This site is exclusive to VUE members only. Please sign-up or log-in.');
        //     bus.$emit('showLogin', true);
        //     return;
        // }

        let strSearchInfo = JSON.stringify(this.searchRoomInfo)
        let strSelHotelInfo = JSON.stringify(this.selectedHotelList)
        
        // this.$router.push({ path: '/resorts2/', query: {
        //   searchStartDate:this.searchStartDate,
        //   searchEndDate:this.searchEndDate,
        //   searchRoomInfo:strSearchInfo,
        //   selHotelInfo:strSelHotelInfo
        // }})
        let routeData = this.$router.resolve({ path: '/resorts2/', query: {
          searchStartDate:this.searchStartDate,
          searchEndDate:this.searchEndDate,
          searchRoomInfo:strSearchInfo,
          selHotelInfo:strSelHotelInfo
        }});
        window.open(routeData.href, '_blank');
      },
      onChangeSearchDate(date, dateString) {
        this.searchStartDate = dateString[0];
        this.searchEndDate = dateString[1];
      },
      showSearchCountPicker(isShow){
        this.visibleSearchCountPicker = isShow;
        if (isShow)
          this.searchRoomInfoID = this.searchRoomInfo.length;
      },
      getCountInfo(_info){
        this.searchRoomInfoID = _info.searchRoomInfoID;
        this.searchRoomInfo.splice(
          this.searchRoomInfoID, 1,
          {
            searchAdultCount : _info.searchAdultCount,
            searchChildCount : _info.searchChildCount,
            searchChildAge : _info.searchChildAge
          }
        )
        this.visibleSearchCountPicker = false;
      },
      
      getCountInfos(_infos){
        this.searchRoomInfo = _infos
        this.visibleSearchCountPicker = false;
      },
      handleCountCancel(){
        this.visibleSearchCountPicker = false;
      },
      removeSearchRoomInfo(_index){
        this.searchRoomInfo.splice(_index, 1);
      },
      editSearchRoomInfo(_index){
        this.visibleSearchCountPicker = true;
        this.searchRoomInfoID = _index;
        this.visibleRoomList = false;
        this.visibleRoomListMobile = false;
      },
      getAllHotelList:async function(){
          const res = await api.getHotelsTotal();
          
          res.sort(function(a,b) {
            let aValue = a['hotelname'];
            let bValue = b['hotelname'];
            return (aValue > bValue) ? 1 : ((bValue > aValue) ? -1 : 0);
            } 
          );
          this.allHotels = [
            {
              _id:"default",
              hotelname:"View All"
            }
          ];
          res.forEach((item)=>{
            if(!item.hotelHidden){
              this.allHotels.push(item);
            }
          })
          // this.allHotels = this.allHotels.concat(res);
          // console.log(this.allHotels,'allhotel====')
      },
      
      getBackgroundImages:async function(){
          const res = await api.getBackgroundImages();
          
          if (res != undefined && res.length > 0){
            this.mainImageInfo = res[0].datalist;
            this.updateHomeImages = !this.updateHomeImages;
          }
      },

      handleFilterChange(){
        if (this.selectedHotelList.length > 0 && this.selectedHotelList[this.selectedHotelList.length - 1] == "default"){
          this.selectedHotelList = ["default"];
          return;
        }
        for (let i = 0; i < this.selectedHotelList.length; i++){
          if (this.selectedHotelList[i] == "default"){
            this.selectedHotelList.splice(i, 1);
          }
          var checkList = this.allHotels.filter(obj => obj._id == this.selectedHotelList[i])
          if (checkList.length <= 0){
            this.selectedHotelList.splice(i, 1);
          }
        }
      },

      clickPerfectResortBtn(){        
        let routeData = this.$router.resolve({ path: '/resorts/'});
        window.open(routeData.href, '_self');
      }
  },
}
</script>

<style>
.front{
  width:100%;
  height: 100%;
}
.back{
  width:100%;
  position: relative;
}
</style>

<style>
  ::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
    .instagramVideo{
        height: 100%;
        width: 100%;
        cursor: pointer;
        object-fit: cover;
        opacity: 1;
        border-radius: 6px;
    }
    .instagramVideo:hover {
        opacity: 0.9;
    }
    .instagramImageDiv {
      position:relative;
      border-radius: 6px;
      overflow: hidden;
      max-height: 38vh;
      height:35vh;
      margin-bottom:0;
      margin-top: auto;
      transform-origin:bottom center;
      display: inline-grid;

    }
    .instagramImageDiv:hover {
        /* transform: scaleY(1); */
      max-height: 38vh;
      height:38vh;
    }
    .instagramImage {
      height: 100%;
      width: 100%;
      cursor: pointer;
      opacity: 1;
      border-radius: 6px;
      /* overflow: hidden; */
    }

    .instagramImage:hover {
        opacity: 0.9;
    }
    .ant-row {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .homeViewBtn{
      font-family: Montserrat light;
      background-color: var(--primary-sub-title-color);
      width:100px;
      height:35px;
      color:white;
      cursor:pointer;
      text-align:center;
      border-color: transparent;
      margin-left:10px;
      margin-right:auto;
      font-weight: 500;
      font-size: 14px;
      line-height: 35px;
    }
    .homeViewBtn:hover{
      background-color: #ada39a;
    }
    .slideHomeMain{
        display: block;
        width:100%;
        height:90vh;
        object-fit: cover;
    }
</style>