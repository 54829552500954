<template>
    <lazy-component class="enhanceMainDiv">
        <div class="enhanceMainTitleDiv">
            <div class="enhanceMainTitle">Checkout</div>
        </div>
        <div class="enhanceContentDiv">
            <div class="enhanceContentMainDiv">
                <div class="enhanceContentImgDiv">
                    <img class="checkOutImage" :src="selectedHotel.hotelimage != '' && selectedHotel.hotelimage != undefined?`${selectedHotel.hotelimage}`:''" alt="">
                </div>
                <div class="enhanceContentInfoDiv">
                    <div class="enhanceContentInfoParent">
                        <div class="enhanceContentInfoCellDiv">
                            <div class="checkoutHotelItemTitle">Hotel:</div>
                            <div class="checkoutHotelItemDetail">{{selectedHotel.hotelname}}</div>
                        </div>
                        <div class="enhanceContentInfoCellDiv">
                            <div class="checkoutHotelItemTitle">Depart:</div>
                            <div class="checkoutHotelItemDetail">{{moment(fromDate).utc().format("MMMM DD, YYYY")}}</div>
                        </div>
                        <div class="enhanceContentInfoCellDiv">
                            <div class="checkoutHotelItemTitle">Return:</div>
                            <div class="checkoutHotelItemDetail">{{moment(toDate).utc().format("MMMM DD, YYYY")}}</div>
                        </div>
                        <div class="enhanceContentInfoCellDiv">
                            <div class="checkoutHotelItemTitle"># of nights: </div><br>
                            <div class="checkoutHotelItemDetail">{{selectedDays}}</div>
                        </div>
                        <div v-for="(item, index) in selectedRoomAllInfo" :key="index"  class="enhanceContentInfoCellDiv">
                            <div class="checkoutHotelItemTitle">Room {{index + 1}}: </div>
                            <div class="checkoutHotelItemDetail">
                                {{item.searchAdultCount}} adults and {{item.searchChildCount}} children
                                <font v-show="item.searchChildCount > 0">(
                                    {{getDisplayAgeStr(item)}}
                                )</font>
                            </div>
                        </div>
                        <div class="enhanceContentInfoCellDiv">
                            <div class="checkoutHotelItemTitle">Room Type: </div>
                            <div>
                                <div v-for="(item, index) in selectedRoom" :key="index" class="checkoutHotelItemDetail">
                                    {{item.roomname}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="enhanceDetailDiv">
            <div class="checkoutEnhancementTitle">
                Enhancement (Add-ons)
            </div>
            <div class="enhanceDetailTopDiv">
                <div ref="add-ons" class="enhanceDetailTopMainDiv">
                    <div v-for="item in enhancementList" :key="item" class="checkoutEnhText1" v-bind:style="[item == selectedEnhuncement ? {'color': '#2C3E4F', 'transform':'scale(1)', 'text-decoration': 'underline', 'text-underline-position': 'under'}
                    : ($mq != 'sm' && $mq != 'ssm' ? {'transform':'scale(0.9)'} : {'transform':'scale(1)'} )]" @click="enhuncementTapClick(item)">
                    <div class="enhanceDetailTopMainText">
                        {{item}}
                    </div>
                </div>

                </div>
            </div>
            <a-form :key="selectedEnhuncement" class="enhanceDetailFormDiv" :form="checoutEnhuncementForm">
                <div v-if="selectedEnhuncement == 'TRANSFER-hiden'">
                    <carousel
                        v-bind:class="[$mq === 'lg' || $mq === 'md' ? 'enhanceImageDiv' : 'enhanceImageDivMobile']"
                        :autoplay="false"
                        :nav="true"
                        :items="1"
                        :loop="false"
                    >
                        <div class="enhanceDetailFormMainDiv" v-for="item, index in transferInfo" :key="index">
                            <div class="enhanceDetailFormSubDiv">
                                <!-- <div class="enhanceDetailFormFirstDiv">
                                    <img class="slideEnhanceImage" :src="item.image != '' && item.image != undefined?`${item.image}`:''">
                                </div> -->
                                <div class="add-ons-content">
                                    <div class="add-ons-detail">
                                        <div class="checkoutItemTitle">
                                            {{item.transfertitle}}
                                        </div>
                                        
                                        <div class="checkoutItemDetails" v-if="checkDetailItem(index, 'TRANSFER')" @click="showDetailItem(index)">DETAILS</div>

                                        <div class="checkoutItemAdultDiv">
                                            <div class="checkoutItemAdultSubDiv">
                                                <div class="checkoutInputLabel">Per adult: {{currencyName}} {{currencySign}}{{addZeroes(Math.round((item.price !=undefined ? item.price.transfer[0].peradultprice : 100) * currency * 100) / 100)}}</div>
                                                
                                                <a-form-item class="checkoutInputForm">
                                                    <vue-numeric-input :min="0" :max="10000" class="checkoutInputFormCell"
                                                        v-decorator="[
                                                            `${CHECKOUTTRANSFERPART}adultcount[${index}]`,
                                                            {
                                                                initialValue: item.selectedInfo != undefined && item.selectedInfo.adultcount != undefined ? item.selectedInfo.adultcount : 0,
                                                                rules: [{ required: true, message: 'childcount title!' }]
                                                            }
                                                        ]">
                                                    </vue-numeric-input>
                                                    <!-- <a-input-number class="checkoutInput" placeholder="" :min="0" :max="10000" style="width:50px"
                                                        v-decorator="[
                                                            `${CHECKOUTTRANSFERPART}adultcount[${index}]`,
                                                            {
                                                                initialValue: 0,//item.selectedInfo != undefined && item.selectedInfo.adultcount != undefined ? item.selectedInfo.adultcount : 0,
                                                                rules: [{ required: true, message: 'childcount title!' }]
                                                            }
                                                        ]"
                                                    />   -->
                                                </a-form-item>   
                                            </div>
                                            <div class="enhanceSummaryLineDiv" v-if="item.price !=undefined && item.price.perchildprice !=undefined"></div>
                                            <div class="checkoutItemAdultSubDiv" v-if="item.price !=undefined && item.price.perchildprice !=undefined">
                                                <div class="checkoutInputLabel">Per child{{item.condition != undefined && item.condition.childagerangefrom != undefined && item.condition.childagerangeto != undefined? "(" + item.condition.childagerangefrom + " - " + item.condition.childagerangeto  + " yrs old):" : 12}} {{currencyName}} {{currencySign}}{{addZeroes(Math.round((item.price !=undefined && item.price.perchildprice !=undefined ? item.price.perchildprice : 100) * currency * 100) / 100)}}</div>
                                                <a-form-item class="checkoutInputForm">
                                                    <vue-numeric-input :min="0" :max="10000" class="checkoutInputFormCell"
                                                        v-decorator="[
                                                            `${CHECKOUTTRANSFERPART}childcount[${index}]`,
                                                            {
                                                                initialValue: item.selectedInfo != undefined && item.selectedInfo.childcount != undefined ? item.selectedInfo.childcount : 0,
                                                                rules: [{ required: true, message: 'childcount title!' }]
                                                            }
                                                        ]">
                                                    </vue-numeric-input>
                                                    <!-- <a-input-number class="checkoutInput" placeholder="" :min="0" :max="10000" style="width:50px"
                                                        v-decorator="[
                                                            `${CHECKOUTTRANSFERPART}childcount[${index}]`,
                                                            {
                                                                initialValue: 0,// item.selectedInfo != undefined && item.selectedInfo.childcount != undefined ? item.selectedInfo.childcount : 0,
                                                                rules: [{ required: true, message: 'childcount title!' }]
                                                            }
                                                            ]"
                                                        />     -->
                                                </a-form-item>    
                                            </div>
                                            <div class="enhanceSummaryLineDiv" v-if="item.price !=undefined && item.price.perinfantprice !=undefined"></div>
                                            <div class="checkoutItemAdultSubDiv" v-if="item.price !=undefined && item.price.perinfantprice !=undefined">
                                                <div class="checkoutInputLabel">Per infant: {{currencyName}} {{currencySign}}{{addZeroes(Math.round((item.price !=undefined && item.price.perinfantprice !=undefined ? item.price.perinfantprice : 100) * currency * 100) / 100)}}</div>
                                                <a-form-item class="checkoutInputForm">
                                                    
                                                    <vue-numeric-input :min="0" :max="10000" class="checkoutInputFormCell"
                                                        v-decorator="[
                                                            `${CHECKOUTTRANSFERPART}infantcount[${index}]`,
                                                            {
                                                                initialValue: item.selectedInfo != undefined && item.selectedInfo.infantcount != undefined ? item.selectedInfo.infantcount : 0,
                                                                rules: [{ required: true, message: 'infantcount title!' }]
                                                            }
                                                        ]">
                                                    </vue-numeric-input>
                                                    <!-- <a-input-number class="checkoutInput" placeholder="" :min="0" :max="10000" style="width:50px" 
                                                        v-decorator="[
                                                            `${CHECKOUTTRANSFERPART}infantcount[${index}]`,
                                                            {
                                                                initialValue: 0,// item.selectedInfo != undefined && item.selectedInfo.infantcount != undefined ? item.selectedInfo.infantcount : 0,
                                                                rules: [{ required: true, message: 'infantcount title!' }]
                                                            }
                                                            ]"
                                                        />     -->
                                                </a-form-item>    
                                            </div>
                                            <div class="enhanceSummaryLineDiv"></div>
                                        </div>

                                    </div>
                                    <div class="checkoutSelect" @click="selectItem(index)">{{item.isSelected != undefined && item.isSelected?'SELECT':'SELECT'}}</div>
                                    </div>
                                </div>
                            
                        </div>
                        
                    </carousel>
                    <div class="arrowsTipText" v-if="transferInfo.length > 1">Click on side arrows to view more options</div>
                </div>
                <div v-if="selectedEnhuncement == 'MEAL PLANS'">
                    <carousel
                        v-bind:class="[$mq === 'lg' || $mq === 'md' ? 'enhanceImageDiv' : 'enhanceImageDivMobile']"
                        :autoplay="false"
                        :nav="true"
                        :items="1"
                        :loop="false"
                    >
                    <!-- 
                        :loop="mealplanInfo != undefined && mealplanInfo.length > 1" -->
                    
                        <div class="enhanceDetailFormMainDiv" v-for="item, index in mealplanInfo" :key="index">
                            <div class="enhanceDetailFormSubDiv">
                                <!-- <div class="enhanceDetailFormFirstDiv">
                                    <img class="slideEnhanceImage" :src="item.image != '' && item.image != undefined?`${item.image}`:''">
                                </div> -->
                                <div class="add-ons-content">
                                    <div class="add-ons-detail">
                                    <div class="checkoutItemTitle">
                                        {{item.mealtitle}}
                                    </div>
                                    
                                    <div class="checkoutItemDetails" v-if="checkDetailItem(index, 'MEAL PLANS')" @click="showDetailItem(index)">DETAILS</div>
                                    <div class="checkoutItemAdultDiv">
                                        <div v-for="(priceitem, priceindex) in item.price" :key="priceindex" style="margin-top:18px;margin-bottom: 0px;">
                                            <div class="checkoutItemAdultSubDiv">
                                                <div class="checkoutInputLabel">Total per {{priceitem.pricetitle}}, per stay: {{currencyName}} {{currencySign}}{{priceitem.pricevalue != undefined && priceitem.pricevalue != "" ? addZeroes(Math.round(priceitem.pricevalue * selectedDays * currency * 100) / 100) : 0.0}} </div>
                                                
                                                <a-form-item class="checkoutInputForm">
                                                    
                                                    <vue-numeric-input :min="0" :max="10000" class="checkoutInputFormCell"
                                                        v-decorator="[
                                                            `${CHECKOUTMEALPLANPART}pricevalue${priceindex}[${index}]`,
                                                            {
                                                                initialValue: item.selectedCountInfo != undefined && item.selectedCountInfo.length > priceindex ? item.selectedCountInfo[priceindex] : 0,
                                                                rules: [{ required: true, message: 'pricevalue title!' }]
                                                            }
                                                        ]">
                                                    </vue-numeric-input>
                                                    <!-- <a-input-number class="checkoutInput" placeholder="" :min="0" :max="10000" style="width:50px" 
                                                        v-decorator="[
                                                            `${CHECKOUTMEALPLANPART}pricevalue${priceindex}[${index}]`,
                                                            {
                                                                initialValue: 0,// item.selectedCountInfo != undefined && item.selectedCountInfo.length > priceindex ? item.selectedCountInfo[priceindex] : 0,
                                                                rules: [{ required: true, message: 'pricevalue title!' }]
                                                            }
                                                            ]"
                                                        />   -->
                                                </a-form-item>  
                                            </div> 
                                            <div class="enhanceSummaryLineDiv"></div>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="checkoutSelect" @click="selectItem(index)">{{item.isSelected != undefined && item.isSelected?'SELECT':'SELECT'}}</div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </carousel>
                    <div class="arrowsTipText" v-if="mealplanInfo.length > 1">Click on side arrows to view more options</div>
                </div>
                <div v-if="selectedEnhuncement == 'FESTIVE'">
                    <carousel
                        v-bind:class="[$mq === 'lg' || $mq === 'md' ? 'enhanceImageDiv' : 'enhanceImageDivMobile']"
                        :autoplay="false"
                        :nav="true"
                        :items="1"
                        :loop="false"
                    >
                    <!-- 
                        :loop="festiveInfo != undefined && festiveInfo.length > 1" -->
                    
                        <div class="enhanceDetailFormMainDiv" v-for="item, index in festiveInfo" :key="index">
                            <div class="enhanceDetailFormSubDiv">
                                <!-- <div class="enhanceDetailFormFirstDiv">
                                    <img class="slideEnhanceImage" :src="item.image != '' && item.image != undefined?`${item.image}`:''">
                                </div> -->
                                <div class="add-ons-content">
                                    <div class="add-ons-detail">
                                    <div class="checkoutItemTitle">
                                        {{item.festivetitle}}
                                    </div>
                                    
                                    <div class="checkoutItemDetails" v-if="checkDetailItem(index, 'FESTIVE')" @click="showDetailItem(index)">DETAILS</div>
                                    <div class="checkoutItemAdultDiv">
                                        <div v-for="(priceitem, priceindex) in item.price" :key="priceindex" style="margin-top:18px;margin-bottom: 0px;">
                                            
                                            <div class="checkoutItemAdultSubDiv">
                                                <div class="checkoutInputLabel">Per {{priceitem.pricetitle.toLowerCase()}}: {{currencyName}} {{currencySign}}{{addZeroes(Math.round(priceitem.pricevalue * currency * 100) / 100)}}</div>
                                                
                                                <a-form-item class="checkoutInputForm">
                                                    
                                                    <vue-numeric-input :min="0" :max="10000" class="checkoutInputFormCell"
                                                        v-decorator="[
                                                            `${CHECKOUTFESTIVEPART}pricevalue${priceindex}[${index}]`,
                                                            {
                                                                initialValue: item.selectedCountInfo != undefined && item.selectedCountInfo.length > priceindex ? item.selectedCountInfo[priceindex] : 0,
                                                                rules: [{ required: true, message: 'pricevalue title!' }]
                                                            }
                                                        ]">
                                                    </vue-numeric-input>
                                                    <!-- <a-input-number class="checkoutInput" placeholder="" :min="0" :max="10000" style="width:45px;margin-right: 0px;"
                                                        v-decorator="[
                                                            `${CHECKOUTFESTIVEPART}pricevalue${priceindex}[${index}]`,
                                                            {
                                                                initialValue: 0,// item.selectedCountInfo != undefined && item.selectedCountInfo.length > priceindex ? item.selectedCountInfo[priceindex] : 0,
                                                                rules: [{ required: true, message: 'pricevalue title!' }]
                                                            }
                                                            ]"
                                                        />   -->
                                                </a-form-item>   
                                            </div>
                                            <div class="enhanceSummaryLineDiv"></div>
                                        </div>
                                    </div>

                                    </div>
                                    <div class="checkoutSelect" @click="selectItem(index)">{{item.isSelected != undefined && item.isSelected?'SELECT':'SELECT'}}</div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </carousel>
                    <div class="arrowsTipText" v-if="festiveInfo.length > 1">Click on side arrows to view more options</div>
                </div>
                <div v-if="selectedEnhuncement == 'HONEYMOON'">
                    <carousel
                        v-bind:class="[$mq === 'lg' || $mq === 'md' ? 'enhanceImageDiv' : 'enhanceImageDivMobile']"
                        :autoplay="false"
                        :nav="true"
                        :items="1"
                        :loop="false"
                    >
                    <!-- 
                        :loop="honeymoonInfo != undefined && honeymoonInfo.length > 1" -->
                    
                        <div class="enhanceDetailFormMainDiv" v-for="item, index in honeymoonInfo" :key="index">
                            <div class="enhanceDetailFormSubDiv">
                                <!-- <div class="enhanceDetailFormFirstDiv">
                                    <img class="slideEnhanceImage" :src="item.image != '' && item.image != undefined?`${item.image}`:''">
                                </div> -->
                                <div class="add-ons-content">
                                    <div class="add-ons-detail">
                                        <div class="checkoutItemTitle">
                                            {{item.honeytitle}}
                                        </div>
                                        <div class="checkoutItemDetails" v-if="checkDetailItem(index, 'HONEYMOON')" @click="showDetailItem(index)">DETAILS</div>
                                        <div class="checkoutItemAdultDiv">
                                            <div style="display:flex;margin-top:0px;height: 30px;">
                                                <div class="checkoutInputLabel">Minimum stay of {{item.stay}} nights </div>
                                                
                                            </div>
                                        </div>
                                        <div class="enhanceSummaryLineDiv"></div>
                                    </div>
                                    <div class="checkoutSelect" @click="selectItem(index)">{{item.isSelected != undefined && item.isSelected?'SELECT':'SELECT'}}</div>
                                            
                                </div>
                            </div>
                        </div>
                        <div class="arrowsTipText">Click on side arrows to view more options</div>
                        
                    </carousel>
                    <div class="arrowsTipText" v-if="honeymoonInfo.length > 1">Click on side arrows to view more options</div>
                </div>
                
            </a-form>
            
            <a-modal
                v-model="visibleDetailItem"
                title=""
                :footer="null"
                :closable="true"
            >
            
                <div style="white-space: pre-wrap;">{{detailItemText}}</div>
            </a-modal>
        </div>
        <div class="enhanceSummaryDiv">
            <div class="checkoutHotelDetail1">Billing Summary</div>
            <div class="enhanceSummaryDetailDiv">
                <div class="checkoutHotelDetail2">Room subtotal</div>
                <div class="checkoutHotelDetail3">{{subPrice > 0 ? currencySign + " " + addZeroes(Math.round((subPrice) * 100) / 100) : "Request Basis"}}</div>
            </div>
            <div class="enhanceSummaryLineDiv"></div>

            <div class="checkoutHotelDetail1" style="margin-top:20px;">Mandatory Service & Fees:</div>
            
            <div class="enhanceSummaryDetailDiv">
                <div class="checkoutHotelDetail2">$6 Green Tax </div>
                <div class="checkoutHotelDetail3">
                    {{currencySign}}{{addZeroes(Math.round(greenTax * currency * 100) / 100)}}
                </div>
            </div>
            <div class="enhanceSummaryLineDiv"></div>
            
            <div class="enhanceSummaryDetailDiv">
                <div class="checkoutHotelDetail2">GST & Services charge </div>
                <div class="checkoutHotelDetail3">
                    {{currencySign}}{{addZeroes(Math.round(gstService * currency * 100) / 100)}}
                </div>
            </div>
            <div class="enhanceSummaryLineDiv"></div>
            
            <div class="enhanceSummaryDetailDiv">
                <div class="checkoutHotelDetail2">Extra Adult Charge</div>
                <div class="checkoutHotelDetail3">
                    {{currencySign}}{{addZeroes(Math.round(extraAdult * currency * 100) / 100)}}
                </div>
            </div>
            <div class="enhanceSummaryLineDiv"></div>
            
            <div class="enhanceSummaryDetailDiv">
                <div class="checkoutHotelDetail2">Extra Child Charge</div>
                <div class="checkoutHotelDetail3">
                    {{currencySign}}{{addZeroes(Math.round(extraChild * currency * 100) / 100)}}
                </div>
            </div>
            <div class="enhanceSummaryLineDiv"></div>

            <div ref="add-values" class="checkoutHotelDetail1" style="margin-top:20px;">Add-ons</div>
            
            <div class="enhanceSummaryDetailDiv" :style="[isTranferSelected ? {'animation':'shake 0.82s cubic-bezier(.36,.07,.19,.97) both', 'background-color':'#00C2CB'} : {'animation':'', 'background-color':'transparent'}]">
                <div class="checkoutHotelDetail2">Transfers</div>
                <div class="checkoutHotelDetail3">
                    {{currencySign}}{{addZeroes(Math.round(this.transferTotalPrice * currency * 100) / 100)}}
                    
                </div>
            </div>
            <div class="enhanceSummaryLineDiv"></div>
            
            <div class="enhanceSummaryDetailDiv" :style="[isMealplanSelected ? {'animation':'shake 0.82s cubic-bezier(.36,.07,.19,.97) both', 'background-color':'#00C2CB'} : {'animation':'', 'background-color':'transparent'}]">
                <div class="checkoutHotelDetail2">Meal Plan</div>
                <div class="checkoutHotelDetail3">
                    {{currencySign}}{{addZeroes(Math.round(mealplanTotalPrice * currency * 100) / 100)}}
                </div>
            </div>
            <div class="enhanceSummaryLineDiv"></div>
            
            <!-- <div class="enhanceSummaryDetailDiv" :style="[isFestiveSelected ? {'animation':'shake 0.82s cubic-bezier(.36,.07,.19,.97) both', 'background-color':'#00C2CB'} : {'animation':'', 'background-color':'transparent'}]">
                <div class="checkoutHotelDetail2">Festive mandatory supplement</div>
                <div class="checkoutHotelDetail3">
                    {{currencySign}}{{addZeroes(Math.round(festiveTotalPrice * currency * 100) / 100)}}
                </div>
            </div> -->
            <div class="enhanceSummaryLineDiv"></div>
            
            <div v-if="honeymoonInfoSelected.length > 0" style="width:100%;" :style="[isHoneymoonSelected ? {'animation':'shake 0.82s cubic-bezier(.36,.07,.19,.97) both', 'background-color':'#00C2CB'} : {'animation':'', 'background-color':'transparent'}]">
                <div class="enhanceSummaryDetailDiv">
                    <div class="checkoutHotelDetail2" >Honeymoon</div>
                    <div class="checkoutHotelDetail3">
                        <div v-for="_info in honeymoonInfoSelected" :key="_info._id">
                            {{_info.honeytitle}}
                        </div>
                    </div>
                </div>
                <div class="enhanceSummaryLineDiv"></div>
            </div> 
            <div class="checkoutHotelDetail5">Final Total: {{grossTotalPrice > 0 ? currencySign + " " + addZeroes(Math.round(grossTotalPrice * currency * 100) / 100) : "Request Basis"}}</div>
                   
            
            <div class="checkoutInclusionseDiv">
                <div class="checkoutPackageTitle">inclusions</div>
                <div v-if="isInclusionShort" class="checkoutPackageDetail1">
                    {{inclusionText}}  
                </div>
            </div>
            
            <div class="checkoutPackageDiv">
                <div class="checkoutPackageTitle">Payment & cancellation</div>
                <div class="checkoutHotelDetail7">
                    {{termsconditions}}   
                </div>
            </div>

            <div class="checkoutTextareaDiv">
                <div class="checkoutTextareaTitleDiv">Special Request (optional)</div>
                <a-textarea class="checkoutTextarea"
                    v-model="specialRequest"
                    prefix="asdasdas"
                    placeholder=""
                    :auto-size="{ minRows: 5, maxRows: 10 }"
                />
            </div>
        </div>

        <div class="enhanceTravelersDiv">
            
            <div class="checkoutEnhancementTitle">Travelers Details</div>
            <a-form class="enhanceTravelersFormDiv" :form="checkoutGuestForm">
                <div v-for="index in searchAdultCount" :key="index">
                    <div class="enhanceTravelersFormCellDiv">
                        <a-form-item class="enhanceTravelersFormItemDiv">
                            <div class="checkoutGuestTitleDiv" style="position:relative;">
                                <div class="checkoutGuestTitle">Guest {{index}}</div>
                                <a-select class="enhanceTravelersSelectDiv" placeholder="Select" :size="'large'" v-decorator="[
                                    `${GUESTINFOPART}sex[${index - 1}]`,
                                    {
                                        initialValue: 'Mr',
                                        rules: [{ required: false, message: 'select this!' }]
                                    }
                                ]">
                                    <a-select-option v-for="_type in personType" :key="_type" :value="_type">
                                        {{ _type }}
                                    </a-select-option>
                                </a-select>
                            </div>
                        </a-form-item>   
                        <div v-if="$mq === 'lg' || $mq === 'md'" style="width:18px"></div>
                        <a-form-item class="enhanceTravelersFormItemDiv2">
                            <div style="position:relative;">
                                <div class="checkoutGuestTitle">First Name</div>
                                <a-input class="enhanceTravelersInputGuest" placeholder="First name" :size="'large'"
                                    v-decorator="[
                                        `${GUESTINFOPART}guestfirstname[${index - 1}]`,
                                        {
                                            initialValue: '',
                                            rules: [{ required: true, message: 'First Name title!' }]
                                        }
                                        ]"
                                    />  
                            </div>
                        </a-form-item>  
                        <div v-if="$mq === 'lg' || $mq === 'md'" style="width:18px"></div> 
                        <a-form-item class="enhanceTravelersFormItemDiv2">
                            <div style="position:relative;">
                                <div class="checkoutGuestTitle">Last Name</div>
                                <a-input class="enhanceTravelersInputGuest" placeholder="Last name" :size="'large'"
                                    v-decorator="[
                                        `${GUESTINFOPART}guestlastname[${index - 1}]`,
                                        {
                                            initialValue: '',
                                            rules: [{ required: true, message: 'Last Name title!' }]
                                        }
                                        ]"
                                    />  
                            </div>
                        </a-form-item>
                    </div>
                    <div v-if="$mq === 'sm' || $mq === 'ssm'" style="width:100%;height: 0px;margin:30px 0px;opacity: 0.2;border: 1px solid var(--desc-color);"></div>
                </div>
                <div v-for="index in searchChildCount" :key="index + searchAdultCount">
                    <div style="display:flex;margin-top:10px;margin-bottom:0px"
                        v-bind:style="[$mq === 'lg' || $mq === 'md' ? {} : {'display':'block'}]">
                        <a-form-item class="enhanceTravelersFormItemDiv">
                            <div class="checkoutGuestTitleDiv" style="position:relative;">
                                <div class="checkoutGuestTitle">Child {{index}} {{'(' + searchChildAge[index - 1] + ' years old)'}}</div>
                                <a-select style="width:150px;margin-top:10px;margin-right:0px;font-family:'General Sans';color: #4F4F4F;" placeholder="Select" :size="'large'" v-decorator="[
                                    `${GUESTINFOPART}sexchild[${index - 1}]`,
                                    {
                                        initialValue: 'Mr',
                                        rules: [{ required: false, message: 'select this!' }]
                                    }
                                ]">
                                    <a-select-option v-for="_type in personType" :key="_type" :value="_type">
                                        {{ _type }}
                                    </a-select-option>
                                </a-select>
                            </div>
                        </a-form-item>  
                        <div v-if="$mq === 'lg' || $mq === 'md'" style="width:18px"></div> 
                        <a-form-item class="enhanceTravelersFormItemDiv2">
                            <div style="position:relative;">
                                <div class="checkoutGuestTitle">First Name</div>                          
                                <a-input class="enhanceTravelersInputGuest" placeholder="First name" :size="'large'"
                                    v-decorator="[
                                        `${GUESTINFOPART}guestfirstnamechild[${index - 1}]`,
                                        {
                                            initialValue: '',
                                            rules: [{ required: true, message: 'First Name title!' }]
                                        }
                                        ]"
                                    />  
                            </div>
                        </a-form-item>   
                        <div v-if="$mq === 'lg' || $mq === 'md'" style="width:18px"></div>
                        <a-form-item class="enhanceTravelersFormItemDiv2">
                            <div style="position:relative;">
                                <div class="checkoutGuestTitle">Last Name</div>        
                                <a-input class="enhanceTravelersInputGuest" placeholder="Last name" :size="'large'"
                                    v-decorator="[
                                        `${GUESTINFOPART}guestlastnamechild[${index - 1}]`,
                                        {
                                            initialValue: '',
                                            rules: [{ required: true, message: 'Last Name title!' }]
                                        }
                                        ]"
                                    />  
                            </div>
                        </a-form-item>
                    </div>
                    <div v-if="$mq === 'sm' || $mq === 'ssm'" class="enhanceSummaryLineDiv"></div>
                </div>
                <div class="enhanceSummaryPersonDiv">
                    <div class="enhanceSummaryPersonContact">
                        <div class="checkoutGuestTitle">Main person contact</div>
                        <div class="enhanceSummaryPersonContact1">
                            <a-input class="enhanceTravelersInputGuest"  v-model="mainPersonPhone" placeholder="Phone" :size="'large'"/>                                
                        </div>
                    </div>
                    <div v-if="$mq === 'lg' || $mq === 'md'" style="width:18px"></div>
                    <div class="enhanceSummaryPersonEmail">
                        <div class="checkoutGuestTitle">Main person email</div>
                        <div class="enhanceSummaryPersonContact1">
                            <a-input class="enhanceTravelersInputGuest"  v-model="mainpersonEmail" placeholder="Email" :size="'large'"/>    
                        </div>
                    </div>
                </div>
                
                
                <div class="bookingResortBtnsDiv">
                    <button class="bookingResort"
                        @click="gotoBookingSummary(0)" >
                        Book now</button>
                    <div style="width:35px;"></div>
                    <button class="homeCreatePdf font-new" v-if="permissionLevel >= 4" :loading="loadingCreateQuote"
                        @click="gotoBookingSummary(1)">
                        Create quote (PDF)</button>
                    <div style="width:35px;"></div>
                    <button class="homeHoldRoom font-new" v-if="permissionLevel >= 4" 
                        @click="gotoBookingSummary(2)" >
                        Hold room</button>
                </div>
            </a-form>
        </div>


        
        <a-modal
            class="checkOutDiv"
            v-model="isShowCheckOut"
            title="Pay Request" 
            :footer="null"
            :width="'100%'"
            :dialog-style="{'max-width': '1080px'}" 
        >
        <!-- :dialog-style="{ 'display': 'flex', 'align-items': 'top', 'max-width': '1080px', 'top': '0', 'width': '100%'}" -->
            <CheckOutSelfComponent style="width:100%;max-width':1080px;padding: 10px;" v-if="isShowCheckOut" :permissionLevel="permissionLevel"
                :payPrice="grossTotalPrice"
                :paying="paying" 
                :mainEmail="mainpersonEmail"
                :mainPhone="mainPersonPhone"
                :bookingReqId="bookingReqId"
                :subPrice="subPrice"
                :greenTax="greenTax"
                :gstService="gstService"
                :extraAdult="extraAdult"
                :extraChild="extraChild"
                :transferTotalPrice="transferTotalPrice"
                :mealplanTotalPrice="mealplanTotalPrice"
                :festiveTotalPrice="festiveTotalPrice"
                :honeymoonInfoSelected="honeymoonInfoSelected"
                :grossTotalPrice="grossTotalPrice"

                 @onClickPay="onClickPay" @onClickPayDirect="onClickPayDirect" @onClickApplePay="onClickApplePay" @close="closePay" :hotelId="selectedHotel._id" :termsconditions="termsconditions"/>
        </a-modal>
        
        <a-modal
            class="errorShow"
            title=""
            :visible="visibleError" 
            >
            <div style="padding: 10px;">
                <a-icon style="margin-right:0;margin-left:auto;position: absolute;top: 10px;right: 10px;" type="close" @click="ErrorCloseClick" :style="{ fontSize: '24px', color:'#AAAAAA' }"/>
                <div style="text-align: center;font-family: Montserrat light;font-size: 15px;line-height: 25px;margin-bottom: 20px;">{{ ErrorText }}<a style="color: var(--primary-button-color);text-decoration: underline;" href="mailto:hello@vuecollection.com"   target="_blank">hello@vuecollection.com</a>.</div>
                <div style="display:flex;">
                    <a-button class="errorBtn" style="margin-left: auto;margin-right: auto;" type="primary" :style="{ fontSize: '20px'}"  @click="ErrorReturnClick">
                        Return to Checkout
                    </a-button>
                </div>
            </div>
        </a-modal>
        
        <a-modal class="SignUpDiv"
            v-model="visibleHoldSuccess"
            title=""
            :footer="null"
            :dialog-style="{'align-items': 'center', 'top': '10px', 'height': '100%'}"
        >
            
            <div style="margin:auto;padding-bottom: 5px;border: 1px solid white;">
                <div style="background-color:var(--primary-sub-title-color);display: flex;padding-left:5px">
                    <img class="logoImage2" style="width:40px;height:40px;" :src="'/images/logo1.png'"/>
                    <div class="logoTitle2" style="font-size: 16px;">Vue Collection</div>
                </div>
                <div style="display:flex;margin-top: 5px;">
                    <div class="SignUpUserTitleAgent" style="margin-left: auto;margin-right: auto;margin-top: 10px;margin-bottom: 10px;font-size: 14px;font-weight: 500;line-height: 20px;color:black">
                        A request has been sent. <br>
                        We will get back to you within 24 hours to confirm the hold.
                    </div>
                </div>
    
            </div>
        </a-modal>
        <a-modal class="SignUpDiv"
            v-model="visibleDirectSuccess"
            title=""
            :footer="null"
            :dialog-style="{'align-items': 'center', 'top': '10px', 'height': '100%'}"
        >
            
            <div style="margin:auto;padding-bottom: 5px;border: 1px solid white;">
                <div style="background-color:var(--primary-sub-title-color);display: flex;padding-left:5px">
                    <img class="logoImage2" style="width:40px;height:40px;" :src="'/images/logo1.png'"/>
                    <div class="logoTitle2" style="font-size: 16px;">Collection</div>
                </div>
                <div style="display:flex;margin-top: 5px;">
                    <div class="SignUpUserTitleAgent" style="margin-left: auto;margin-right: auto;margin-top: 10px;margin-bottom: 10px;font-size: 14px;font-weight: 500;line-height: 20px;color:black">
                        A request to make a bank transfer has been received. <br>
                        We will get back to you with more details within 24 hours.
                    </div>
                </div>
    
            </div>
        </a-modal>
    </lazy-component>
</template>

<script>
// import { api } from '@/helpers/Helpers';
import moment from 'moment';
// import CheckOutComponent from '@/components/check-out.vue';
import CheckOutSelfComponent from '@/components/check-out-self.vue';
import { api } from '@/helpers/Helpers';
import carousel from 'vue-owl-carousel';
import VueNumericInput from 'vue-numeric-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
const kindData = [
    {
        name : "Shared Seaplane Round-trip Transfers"
    },
    {
        name : "Shared Domestic flight Round-trip Transfers"
    },
];
const personType = ['Mr', 'Mrs', 'Ms', 'Mx']

const CHECKOUTTRANSFERPART = 'checkoutTransferPart';
const CHECKOUTMEALPLANPART = 'checkoutMealplanPart';
const CHECKOUTFESTIVEPART = 'checkoutFestivePart';
const CHECKOUTHONEYMOONPART = 'checkoutHoneymoonPart';
const GUESTINFOPART = 'guestInfoPart';

const payInfo = {
    name:'', 
    email:'', 
    address:'', 
    city:'', 
    state:'', 
    zip:''
}
export default {
    components: { 
        // CheckOutComponent,
        CheckOutSelfComponent,
        carousel,
        VueNumericInput
    },
    
    data() {
        return {
            kindData,
            personType,
            specialRequest:'',

            hotelId:'',
            selectedHotel:{},
            roomId:'',
            selectedRoom:[],
            promotionindex:0,
            mainPrice:0.0,
            subPrice:0.0,
            greenTax:0.0,
            gstService:0.0,
            extraAdult:0.0,
            extraChild:0.0,
            commission:0.0,
            transferPrice:0.0,
            mealplanPrice:0.0,
            festivePrice:0.0,
            grossTotalPrice:0.0,

            totalprice:0.0,
            sellingTotalPrice:0.0,
            sellingPrice:0.0,
            hoteltovue:0.0,
            contrackctedPrice:0.0,

            inclusionTextShort:'',
            termsconditionsShort:'',
            inclusionText:'',
            termsconditions:'',
            isInclusionShort:true,
            isTermsConditionShort:true,

            enhancementList:[],
            transferInfo:[],
            mealplanInfo:[],
            festiveInfo:[],
            honeymoonInfo:[],

            honeymoonInfoSelected:[],

            
            checoutEnhuncementForm: this.$form.createForm(this),
            CHECKOUTTRANSFERPART,
            CHECKOUTMEALPLANPART,
            CHECKOUTFESTIVEPART,
            CHECKOUTHONEYMOONPART,

            checkoutGuestForm: this.$form.createForm(this),
            GUESTINFOPART,

            selectedEnhuncement:"TRANSFER",

            transferSelectedList:[],
            mealplanSelectedList:[],
            festiveSelectedList:[],
            honeymoonSelectedList:[],

            guestList:[ 
                {
                    sex:'Mr',
                    firstname:'',
                    lastname:'',
                },
                {
                    sex:'Mr',
                    firstname:'',
                    lastname:'',
                }
            ],
            mainPersonPhone:'',
            mainpersonEmail:'',

            searchAdultCount:0,
            searchChildCount:0,
            searchChildAge:[],
            displayAgeStr:'',


            fromDate:null,
            toDate:null,
            selectedDays:0,
            
            currency:1.0,
            currencyName:'USD',
            currencySign:'$',

            transferTotalPrice:0,
            mealplanTotalPrice:0,
            festiveTotalPrice:0,
            
            transferTotalText:[],
            mealplanTotalText:[],
            festiveTotalText:[],
            honeymoonTotalText:[],

            isShowCheckOut: false,
            bookingReqData:[],
            bookingReqId:'',
            hotelImage:'',

            payInfo,

            guestArr:[],//guest name list
            guestChildArr:[],//guest child name list
            includeList:[],

            permissionLevel: 1,
            customerId: '',

            
            offerName : '',
            roomName : '',

            paying:false,

            visibleDetailItem: false,
            detailItemText:'',

            isTranferSelected: false,
            isMealplanSelected: false,
            isFestiveSelected: false,
            isHoneymoonSelected: false,

            isAddonsUpdate: false,

            visibleError:false,
            ErrorText:'',

            firstName:'',
            lastName: '',
            agencyName: '',

            visibleHoldSuccess:false,
            visibleDirectSuccess:false,

            loadingCreateQuote:false,

            bookingCode:''

        };
    },
    props: {
        selectedRoomAllInfo:[],
        searchStartDate:Number,
        searchEndDate:Number,
    },
    created(){
        
        this.currency = this.$store.state.selectedCurrency.rate;
        this.currencyName = this.$store.state.selectedCurrency.name;
        this.currencySign = this.$store.state.selectedCurrency.sign;
        
        if (this.$store.state.loginUser !== null){
            
            const loginType = this.$store.state.loginUser.loginType;
            this.permissionLevel = this.$store.state.loginUser[loginType].permissionLevel;
            this.customerId = this.$store.state.loginUser[loginType].customerId;
            
            this.firstName = this.$store.state.loginUser[loginType].user.firstname;
            this.lastName = this.$store.state.loginUser[loginType].user.lastname;
            this.agencyName = this.$store.state.loginUser[loginType].user.agencyname;
        }

        this.visibleHoldSuccess = false;
        this.initData();
    },
    
    mounted(){

    },
    methods: {
        moment,
        
        validatePhone() {
            if (!this.mainPersonPhone.startsWith('+')) {
                this.mainPersonPhone = "+" + this.mainPersonPhone;
            }
            const phoneNumber = parsePhoneNumberFromString(this.mainPersonPhone);
            return phoneNumber ? phoneNumber.isValid() : false;
        },
        successPay(isSuccess = false, paymentId = ''){
            if (isSuccess){
                this.updateBookingStatus(paymentId, 1);//Paid status
            }
        },

        onClickPayDirect:async function(){

            const res = await api.sendPayDirect(this.bookingReqId);
            if (res != undefined){
                this.isShowCheckOut = false;
                this.visibleDirectSuccess = true;
                // this.$router.push({ path: '/bookingsummary/', query: {
                //     bookingReqId: this.bookingReqId,
                // }});
            }
        },
        closePay(){
            this.isShowCheckOut = false;
        },
        onClickApplePay(paymentId){

            if (paymentId != undefined){
                this.updateBookingStatus(paymentId, 1);//Paid status
                this.isShowCheckOut = false;
            }
            // this.$router.push({ path: '/bookingsummary/', query: {
            //     bookingReqId: this.bookingReqId,
            // }});
        },

        onClickPay(cardInfo){
            this.paying = true;
            
            const cardNumber = cardInfo.cardNumber.replaceAll(' ','');
            
            const expMonth = parseInt(cardInfo.date.substring(0, 2));
            const expYear = parseInt(cardInfo.date.slice(-2));
            const cardCvc = cardInfo.cvc;
            const price = cardInfo.price;
            
            this.createCard(cardNumber, expMonth, expYear, cardCvc, price);
        },
        addZeroes(num){
            let textNum = num.toString();
            let decimalResult = Number(textNum).toFixed(Math.max(textNum.split('.')[1]?.length, 2) || 2);
            let thousand = decimalResult.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return thousand;
        },
        calcTransfer(index) {
            const diffTimestampOffset = new Date().getTimezoneOffset() * 60;
            // const transferArray = this.selectedHotel.transfer.filter(obj =>
            //     this.getDays(moment(obj.condition.transferstart).utc().unix()) <= this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset)
            //     && this.getDays(moment(obj.condition.transferend).utc().unix()) >= this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset));
            const transferArray = this.selectedHotel.transfer.filter(obj =>
                obj.condition.transferDate.some(date => 
                    this.getDays(moment(date.transferstart).utc().unix()) <= 
                    this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset) &&
                    this.getDays(moment(date.transferend).utc().unix()) >= 
                    this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset)
                )
            );


            if(transferArray.length > 0){
                if(transferArray[0].price!=undefined && transferArray[0].price.transfer != undefined && transferArray[0].price.transfer.length > 0){
                    let peradultprice = 0;
                    let perchildprice = 0;
                    let perinfantprice = 0;
                    transferArray.forEach((array)=>{
                        array.price.transfer.forEach((item)=>{
                            if(this.selectedDays>=item.min && this.selectedDays<=item.max){
                                peradultprice = item.peradultprice;
                                perchildprice = item.perchildprice;
                                perinfantprice = item.perinfantprice;
                            }
                        })
                    })
                    // this.selectedRoomAllInfo[0].searchChildAge.forEach((item)=>{
                    //     if(item < transferArray[0].condition.childagerangefrom){
                    //         childPrice += perinfantprice;
                    //     }else{
                    //         childPrice += perchildprice
                    //     }
                    // });

                    let childPrice = 0;
                    this.selectedRoomAllInfo[index].searchChildAge.forEach((item)=>{
                        if(item > transferArray[0].condition.childagerangeto){
                            childPrice += peradultprice;
                        }else if(item >= transferArray[0].condition.childagerangefrom){
                            childPrice += perchildprice;
                        } else {
                          childPrice += perinfantprice;
                        }
                    });

                    const transferPrice = peradultprice * this.selectedRoomAllInfo[index].searchAdultCount +  childPrice
                    // this.transferTotalPrice=transferPrice;
                    return transferPrice; 
                }
 
                let childPrice = 0;
                this.selectedRoomAllInfo[index].searchChildAge.forEach((item)=>{
                    if(item > transferArray[0].condition.childagerangeto){
                        childPrice += transferArray[0].price.peradultprice;
                    } else if(item >= transferArray[0].condition.childagerangefrom) {
                        childPrice += transferArray[0].price.perchildprice
                    } else  {
                        childPrice += transferArray[0].price.perinfantprice
                    }
                });

                const transferPrice = transferArray[0].price.peradultprice * this.selectedRoomAllInfo[index].searchAdultCount + childPrice
                this.transferTotalPrice=transferPrice;
                return transferPrice; 
            }
             return 0

            // let peradultprice=0;
            // let perchildprice=0;
            // transferArray[0].price.transfer.forEach((item)=>{
            //     if(this.selectedDays>=item.min && this.selectedDays<=item.max){
            //         peradultprice = item.peradultprice;
            //         perchildprice = item.perchildprice;
            //     }
            // })
            // const transferPrice = peradultprice * this.selectedRoomAllInfo[0].searchAdultCount +  perchildprice * this.selectedRoomAllInfo[0].searchChildCount
            
            // return transferPrice;
        },
        initData(){
            this.isShowCheckOut = false;
            
            
            this.hotelId = this.selectedRoomAllInfo[0].hotelid;
            if (this.selectedRoomAllInfo[0].roomAllInfo != undefined){
                this.selectedHotel = this.selectedRoomAllInfo[0].roomAllInfo.hotelinfo;
                this.roomId = this.selectedRoomAllInfo[0].roomAllInfo.roomid;
                this.selectedRoomAllInfo.map((roomInfo) => {
                    this.selectedRoom.push(roomInfo.roomAllInfo.roominfo);
                })
            }
            this.promotionindex = this.selectedRoomAllInfo[0].promotionindex;
            
            
            this.searchAdultCount = 0;
            this.searchChildCount = 0;

            this.searchChildAge = [];
            // if (this.selectedRoomAllInfo[0].searchChildAge != undefined)
            //     this.searchChildAge = this.selectedRoomAllInfo[0].searchChildAge;
            
            const diffTimestampOffset = new Date().getTimezoneOffset() * 60;
            
            this.fromDate = new Date(this.searchStartDate);
            this.toDate = new Date(this.searchEndDate);
            
            const diffTime = Math.abs(this.toDate - this.fromDate);
            this.selectedDays = Math.round(diffTime / (1000 * 60 * 60 * 24)); 

            this.mainPrice = 0;
            this.subPrice = 0;
            this.greenTax = 0;
            this.gstService = 0;
            this.extraAdult = 0;
            this.extraChild = 0;
            this.commission = 0;
            this.grossTotalPrice = 0;

            this.totalprice = 0;
            this.sellingTotalPrice = 0;
            this.sellingPrice = 0;
            this.hoteltovue = 0;
            this.contrackctedPrice = 0;

            this.bookingReqData = [];

            this.offerName = '';
            this.roomName = '';

            this.bookingCode = '';
            for (let i =0; i < this.selectedRoomAllInfo.length; i++){
                const roomAllInfo = this.selectedRoomAllInfo[i].roomAllInfo;
                if (roomAllInfo != undefined){
                    const promotionPrice = roomAllInfo.promotionprice[this.selectedRoomAllInfo[i].promotionindex];
                    this.subPrice += promotionPrice.totalprice - promotionPrice.greenTax - promotionPrice.gstService;
                    this.greenTax += promotionPrice.greenTax;
                    this.gstService += promotionPrice.gstService;
                    this.extraAdult += promotionPrice.extraAdultCharge;
                    this.extraChild += promotionPrice.extraChildCharge;
                    this.commission += promotionPrice.commission;
                    this.transferTotalPrice += this.calcTransfer(i);
                    this.grossTotalPrice += promotionPrice.totalprice + this.calcTransfer(i);

                    
                    this.contrackctedPrice += promotionPrice.contrackctedPrice;
                    this.sellingPrice += promotionPrice.sellingPrice;
                    this.hoteltovue += promotionPrice.hoteltovue;
                    this.sellingTotalPrice += promotionPrice.sellingTotalPrice;
                    this.totalprice += promotionPrice.totalprice;

                    this.searchAdultCount += this.selectedRoomAllInfo[i].searchAdultCount;
                    this.searchChildCount += this.selectedRoomAllInfo[i].searchChildCount;

                    this.searchChildAge = this.searchChildAge.concat(this.selectedRoomAllInfo[i].searchChildAge);
                    
                    let _promotion = this.selectedRoomAllInfo[i].promotioninfo;
                    if (_promotion != undefined){ 
                        this.offerName = _promotion.title;
                    }
                    if (this.selectedRoomAllInfo[i] != undefined && this.selectedRoomAllInfo[i].roomAllInfo != undefined && this.selectedRoomAllInfo[i].roomAllInfo.roominfo != undefined){ 
                        this.roomName = this.selectedRoomAllInfo[i].roomAllInfo.roominfo.roomname;
                    }
                    if (_promotion != undefined && _promotion.description != undefined){ 
                        this.inclusionText = _promotion.description;
                        this.inclusionTextShort = _promotion.description;
                    }
                    else{
                        
                        this.inclusionText = "";
                        this.inclusionTextShort = this.inclusionText;
                    }

                    if (_promotion != undefined && _promotion.termsconditions != undefined){ 
                        this.termsconditions = _promotion.termsconditions;
                        this.termsconditionsShort = _promotion.termsconditions;
                    }
                    else{
                        this.termsconditions = "";
                        this.termsconditionsShort = this.termsconditions;
                    }
                    if (_promotion != undefined && _promotion.bookingcode != undefined){ 
                        if (this.bookingCode == '')
                            this.bookingCode = _promotion.bookingcode;
                        // else
                        //     this.bookingCode += ', ' + _promotion.bookingcode;
                    }

                    this.bookingReqData.push({
                        roomid: this.selectedRoomAllInfo[i].roomAllInfo.roomid,
                        roomname: this.selectedRoomAllInfo[i].roomAllInfo.roominfo.roomname,
                        adultcount: this.selectedRoomAllInfo[i].searchAdultCount,
                        childcount: this.selectedRoomAllInfo[i].searchChildCount,
                        childages: this.selectedRoomAllInfo[i].searchChildAge,
                        promotionid: this.selectedRoomAllInfo[i].promotioninfo._id,
                        promotionname: this.selectedRoomAllInfo[i].promotioninfo.title,
                        price:promotionPrice.totalprice,
                    })
                }
            }

            this.guestList = [];
            for(let i = 0; i < this.searchAdultCount; i++){
                this.guestList.push(
                {
                    sex:'Mr',
                    firstname:'',
                    lastname:'',
                })
            }
            


            this.enhancementList = [];
            if (this.selectedHotel.transfer != undefined && this.selectedHotel.transfer.length > 0){
                // this.transferInfo = this.selectedHotel.transfer.filter(obj =>
                //         this.getDays(moment(obj.condition.transferstart).utc().unix()) <= this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset)
                //         && this.getDays(moment(obj.condition.transferend).utc().unix()) >= this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset));
                this.transferInfo = this.selectedHotel.transfer.filter(obj =>
                    obj.condition.transferDate.some(date => 
                        this.getDays(moment(date.transferstart).utc().unix()) <= 
                        this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset) &&
                        this.getDays(moment(date.transferend).utc().unix()) >= 
                        this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset)
                    )
                );
            }
                        
            // if (this.selectedHotel.mealplan != undefined && this.selectedHotel.mealplan.length > 0){
            //     this.mealplanInfo = this.selectedHotel.mealplan.filter(obj => 
            //         this.getDays(moment(obj.mealdate != undefined ? obj.mealdate.mealstart : null).utc().unix()) <= this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset)
            //         && this.getDays(moment(obj.mealdate != undefined ? obj.mealdate.mealend : null).utc().unix()) >= this.getDays(moment(this.searchEndDate).utc().unix() - diffTimestampOffset));
            //     if (this.mealplanInfo.length > 0) 
            //         this.enhancementList.push("MEAL PLANS");
            // }
            if (this.selectedHotel.mealplan !== undefined && this.selectedHotel.mealplan.length > 0) {
                this.mealplanInfo = this.selectedHotel.mealplan.filter(obj => {
                    if (Array.isArray(obj.mealdate)) {
                        return obj.mealdate.some(date => {
                            const mealStart = moment(date.mealstart).utc().unix();
                            const mealEnd = moment(date.mealend).utc().unix();

                            return (
                                this.getDays(mealStart) <= this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset) &&
                                this.getDays(mealEnd) >= this.getDays(moment(this.searchEndDate).utc().unix() - diffTimestampOffset)
                            );
                        });
                    }
                    return false;
                });

                if (this.mealplanInfo.length > 0) {
                    this.enhancementList.push("MEAL PLANS");
                }
            }

            
            if (this.selectedHotel.festive != undefined && this.selectedHotel.festive.length > 0){
                this.festiveInfo = this.selectedHotel.festive.filter(obj => 
                    this.getDays(moment(obj.condition).utc().unix()) >= this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset)
                    && this.getDays(moment(obj.condition).utc().unix()) < this.getDays(moment(this.searchEndDate).utc().unix() - diffTimestampOffset));
                if (this.festiveInfo.length > 0)
                    this.enhancementList.push("FESTIVE");
                
            }
            if (this.selectedHotel.honeymoon != undefined && this.selectedHotel.honeymoon.length > 0){
                this.honeymoonInfo = this.selectedHotel.honeymoon.filter(obj => (obj.stay == undefined || obj.stay <= this.selectedDays) && obj.honeydate != undefined
                    && this.getDays(moment(obj.honeydate.honeystart).utc().unix()) <= this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset)
                    && this.getDays(moment(obj.honeydate.honeyend).utc().unix()) >= this.getDays(moment(this.searchEndDate).utc().unix() - diffTimestampOffset));
                if (this.honeymoonInfo.length > 0)   
                    this.enhancementList.push("HONEYMOON");
            }
            if (this.enhancementList != undefined && this.enhancementList.length > 0)
                this.selectedEnhuncement = this.enhancementList[0]


            this.hotelImage = this.selectedHotel.hotelimage;
            const loginType = this.$store.state.loginUser.loginType;
            this.payInfo.name = this.$store.state.loginUser[loginType].user.name;
            this.payInfo.email = this.$store.state.loginUser[loginType].user.email;
                // address:'', 
                // city:'', 
                // state:'', 
                // zip:''
            
        },
        getDays(_second){
            return Math.floor(_second / (24 * 60 * 60));
        },
        getDisplayAgeStr(_info){
            let displayAgeStrList = [];
            if(_info.searchChildAge != undefined && _info.searchChildAge.length > 0){
                _info.searchChildAge.forEach((element) => {
                    displayAgeStrList.push(element + ' years old');
                });
            }
            return displayAgeStrList.join(', ');
        },


        
    // eslint-disable-next-line no-unused-vars
        handlePersonType(value) {
            //	this.cities = cityData[value];
            //	this.secondCity = cityData[value][0];
        },
        enhuncementTapClick(_name){
            this.selectedEnhuncement = _name;
        },
        showDetailItem(_index){
            this.detailItemText = '';
            if (this.selectedEnhuncement == "TRANSFER"){
                this.detailItemText = this.transferInfo[_index].description;
            }
            
            if (this.selectedEnhuncement == "MEAL PLANS"){
                this.detailItemText = this.mealplanInfo[_index].description;
            }
            
            if (this.selectedEnhuncement == "FESTIVE"){
                this.detailItemText = this.festiveInfo[_index].description;
            }
            
            if (this.selectedEnhuncement == "HONEYMOON"){
                this.detailItemText = this.honeymoonInfo[_index].description;
            }
            if (this.detailItemText != undefined && this.detailItemText != '')
                this.visibleDetailItem = true;
        },
        
        checkDetailItem(_index, _tab){
            if (_tab == "TRANSFER"){
                if (this.transferInfo[_index] == undefined || this.transferInfo[_index].description == undefined || this.transferInfo[_index].description == '')
                    return false;
                return true;
            }
            
            if (_tab == "MEAL PLANS"){
                if (this.mealplanInfo[_index] == undefined || this.mealplanInfo[_index].description == undefined || this.mealplanInfo[_index].description == '')
                    return false;
                return true;
            }
            
            if (_tab == "FESTIVE"){
                if (this.festiveInfo[_index] == undefined || this.festiveInfo[_index].description == undefined && this.festiveInfo[_index].description == '')
                    return false;
                return true;
            }
            
            if (_tab == "HONEYMOON"){
                if (this.honeymoonInfo[_index] == undefined || this.honeymoonInfo[_index].description == undefined && this.honeymoonInfo[_index].description == '')
                    return false;
                return true;
            }
        },
        selectItem(_index){
            
            const { checoutEnhuncementForm: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    if (this.selectedEnhuncement == "TRANSFER"){
                        
                        if (values[`${CHECKOUTTRANSFERPART}adultcount`] != undefined){
                            const selectedInfo = {
                                type:"TRANSFER",
                                typeid:this.transferInfo[_index]._id,
                                adultAgeMin:this.transferInfo[_index].condition.adultagerangefrom,
                                childAgeMin:this.transferInfo[_index].condition.childagerangefrom,
                                adultcount:values[`${CHECKOUTTRANSFERPART}adultcount`] != undefined && values[`${CHECKOUTTRANSFERPART}adultcount`][_index] != undefined ? values[`${CHECKOUTTRANSFERPART}adultcount`][_index] : 0,
                                childcount:values[`${CHECKOUTTRANSFERPART}childcount`] != undefined && values[`${CHECKOUTTRANSFERPART}childcount`][_index] != undefined ? values[`${CHECKOUTTRANSFERPART}childcount`][_index] : 0,
                                infantcount:values[`${CHECKOUTTRANSFERPART}infantcount`] != undefined && values[`${CHECKOUTTRANSFERPART}infantcount`][_index] != undefined ? values[`${CHECKOUTTRANSFERPART}infantcount`][_index] : 0
                            }

                            let checkIndex = this.transferSelectedList.findIndex(object => {
                                return object.typeid === selectedInfo.typeid;
                            });

                            let _tempInfo = this.transferInfo[_index];
                            if (checkIndex != -1){
                                this.transferSelectedList.splice(checkIndex, 1, selectedInfo);
                                // _tempInfo['isSelected'] = false;
                            }
                            else{
                                this.transferSelectedList.push(selectedInfo);
                            }
                            _tempInfo['isSelected'] = true;
                            _tempInfo['selectedInfo'] = selectedInfo;

                            if (this.transferSelectedList.length > 0 && this.transferInfo.length > 0)
                            {
                                var adultcount = 0;
                                var childcount = 0;
                                var infantcount = 0;
                                for (let i = 0; i < this.transferSelectedList.length; i++){
                                    adultcount += this.transferSelectedList[i].adultcount != undefined ? this.transferSelectedList[i].adultcount : 0;
                                    childcount += this.transferSelectedList[i].childcount != undefined ? this.transferSelectedList[i].childcount : 0;
                                    infantcount += this.transferSelectedList[i].infantcount != undefined ? this.transferSelectedList[i].infantcount : 0;
                                }
                                
                                var searchAdultCount = 0;
                                var searchChildCount = 0;
                                var searchChildAges = [];
                                var searchInfantCount = 0;
                                for (let i = 0; i < this.selectedRoomAllInfo.length; i++){
                                    searchAdultCount += this.selectedRoomAllInfo[i].searchAdultCount;
                                    // searchChildCount += this.selectedRoomAllInfo[i].searchChildCount;
                                    searchChildAges = searchChildAges.concat(this.selectedRoomAllInfo[i].searchChildAge);
                                }
                                // searchAdultCount += searchChildAges.filter(obj => obj >= 12).length;
                                let adultMinAge = 12;
                                if (selectedInfo != undefined && selectedInfo.adultAgeMin != undefined)
                                    adultMinAge = selectedInfo.adultAgeMin;
                                let childMinAge = 2;
                                if (selectedInfo != undefined && selectedInfo.childAgeMin != undefined)
                                    childMinAge = selectedInfo.childAgeMin;

                                searchChildCount = searchChildAges.filter(obj => obj >= childMinAge).length;
                                searchInfantCount = searchChildAges.filter(obj => obj < childMinAge).length;

                                let _childAdultCount = searchChildAges.filter(obj => obj >= adultMinAge).length;
                                if (adultcount > searchAdultCount + _childAdultCount){
                                    this.$message.error('Please select transfer for adult correctly');
                                    this.transferSelectedList.pop();
                                    _tempInfo['isSelected'] = false;
                                    return;
                                }
                                if (childcount > searchChildCount - _childAdultCount){
                                    this.$message.error('Please select transfer for child correctly');
                                    this.transferSelectedList.pop();
                                    _tempInfo['isSelected'] = false;
                                    return;
                                }
                                if (infantcount > searchInfantCount){
                                    this.$message.error('Please select transfer for infant correctly');
                                    this.transferSelectedList.pop();
                                    _tempInfo['isSelected'] = false;
                                    return;
                                }
                            }

                            this.transferInfo.splice(_index, 1, _tempInfo);
                            this.updateTransferPrice();
                            // window.scrollTo(0,0);
                            let element = this.$refs["add-values"];
                            window.scrollTo(0, element.offsetTop);
                            this.isTranferSelected = true;
                            setTimeout(() => {
                                this.isTranferSelected = false;
                            }, 5000);
                            
            
                        }
                    }
                    
                    if (this.selectedEnhuncement == "MEAL PLANS"){ 
                        var mealplanPriceList = this.mealplanInfo[_index] != undefined && this.mealplanInfo[_index].price != undefined ? this.mealplanInfo[_index].price : [];
                        for (let i = 0; i < this.selectedRoomAllInfo.length; i++){
                            this.selectedRoomAllInfo[i]['isCheckedCount'] = false;
                        }
                        for (let i = 0; i < mealplanPriceList.length; i++){
                            var standardCount = 0;// mealplanPriceList[i] != undefined && mealplanPriceList[i].standardCount != undefined ? mealplanPriceList[i].standardCount : 0;
                            
                            for (let j = 0; j < this.selectedRoomAllInfo.length; j++){
                                var _searchAdultCount = this.selectedRoomAllInfo[j].searchAdultCount; 
                                
                                var isCheckedAdultCount = false;
                                if (this.selectedRoomAllInfo[j]['isCheckedCount'] != undefined)
                                    isCheckedAdultCount = this.selectedRoomAllInfo[j]['isCheckedCount']
                                if (_searchAdultCount > 0 && mealplanPriceList[i].agefrom <= 25 && mealplanPriceList[i].ageto >= 25 && !isCheckedAdultCount){
                                    standardCount += _searchAdultCount;
                                    this.selectedRoomAllInfo[j]['isCheckedCount'] = true;
                                }
                                for (let k = 0; k < this.selectedRoomAllInfo[j].searchChildCount; k++){
                                    let _childAge = this.selectedRoomAllInfo[j].searchChildAge[k];
                                    if (mealplanPriceList[i].agefrom <= _childAge && mealplanPriceList[i].ageto >= _childAge){
                                        
                                        standardCount += 1;
                                    }
                                }
                                // searchChildAges = searchChildAges.concat(this.selectedRoomAllInfo[i].searchChildAge);
                            }
                            mealplanPriceList[i]['standardCount'] = standardCount;
                        }
                        
                        // for (let i = 0; i < this.selectedRoomAllInfo.length; i++){
                        //     searchAdultCount += this.selectedRoomAllInfo[i].searchAdultCount;
                        //     searchChildAges = searchChildAges.concat(this.selectedRoomAllInfo[i].searchChildAge);
                        // }

                        let _mealPrice = 0;
                        let _mealPriceCountList = [];

                        let checkMealplabCount = true;
                        for (let i = 0; i < mealplanPriceList.length; i++){
                            if (values[`${CHECKOUTMEALPLANPART}pricevalue${i}`] != undefined){
                                let _inpuPrice = values[`${CHECKOUTMEALPLANPART}pricevalue${i}`][_index] != undefined ? values[`${CHECKOUTMEALPLANPART}pricevalue${i}`][_index] : 0;
                                _mealPrice += mealplanPriceList[i].pricevalue * this.selectedDays * _inpuPrice;
                                _mealPriceCountList.push(_inpuPrice);
                                if (_inpuPrice != mealplanPriceList[i].standardCount)
                                    checkMealplabCount = false;
                            }
                        }
                        if (_mealPrice > 0 && !checkMealplabCount){
                            this.$message.error('Please select mealplan correctly');
                            return;
                        }

                        const selectedInfo = {
                            type:"MEAL PLANS",
                            typeid:this.mealplanInfo[_index]._id,
                            pricevalue:_mealPrice,
                        }

                        let checkIndex = this.mealplanSelectedList.findIndex(object => {
                            return object.typeid === selectedInfo.typeid;
                        });

                        if (checkIndex != -1){
                            if (_mealPrice <= 0){
                                this.mealplanSelectedList.splice(checkIndex, 1);
                            }
                            else{
                                this.mealplanSelectedList.splice(checkIndex, 1, selectedInfo)
                            }
                            // _tempInfo['isSelected'] = false;
                        }
                        else{
                            if (_mealPrice > 0){
                                this.mealplanSelectedList.push(selectedInfo)
                            }
                        }

                        let _tempInfo = this.mealplanInfo[_index];
                        
                        _tempInfo['isSelected'] = _mealPrice > 0;
                        _tempInfo['selectedInfo'] = selectedInfo;
                        _tempInfo['selectedCountInfo'] = _mealPriceCountList;

                        this.mealplanInfo.splice(_index, 1, _tempInfo);
                        this.updateMeanplanPrice();

                        // window.scrollTo(0,0);
                        
                        let element = this.$refs["add-values"];
                        window.scrollTo(0, element.offsetTop);
                        this.isMealplanSelected = true;
                        setTimeout(() => {
                            this.isMealplanSelected = false;
                        }, 1000);
                    }
                    
                    if (this.selectedEnhuncement == "FESTIVE"){
                        let _tempInfo = this.festiveInfo[_index];
                        let _festivePrice = 0;
                        let _festivePriceCountList = [];
                        
                        let _festivePriceAgeList = [];

                        // let searchAdultCount = 0;
                        // let searchChildCount = 0;
                        for (let i = 0; i < this.selectedRoomAllInfo.length; i++){
                            searchAdultCount += this.selectedRoomAllInfo[i].searchAdultCount;
                            searchChildCount += this.selectedRoomAllInfo[i].searchChildCount;
                        }


                        for (let i = 0; i < _tempInfo.price.length; i++){
                            if (values[`${CHECKOUTFESTIVEPART}pricevalue${i}`] != undefined){
                                
                                // if (i == 0 && values[`${CHECKOUTFESTIVEPART}pricevalue${i}`][_index] > searchAdultCount){
                                //     this.$message.error('Please select festive for adult correctly');
                                //     this.festiveSelectedList.pop();
                                //     return;
                                // }
                                // if (i >= 1 && values[`${CHECKOUTFESTIVEPART}pricevalue${i}`][_index] > searchChildCount){
                                //     this.$message.error('Please select festive for child correctly');
                                //     this.festiveSelectedList.pop();
                                //     return;
                                // }
                                let _inpuPrice = values[`${CHECKOUTFESTIVEPART}pricevalue${i}`][_index] != undefined ? values[`${CHECKOUTFESTIVEPART}pricevalue${i}`][_index] : 0;
                                _festivePrice += _tempInfo.price[i].pricevalue * _inpuPrice;
                                _festivePriceCountList.push(_inpuPrice);
                                _festivePriceAgeList.push(_tempInfo.price[i]);
                            }
                        }
                        const selectedInfo = {
                            type:"FESTIVE",
                            typeid:this.festiveInfo[_index]._id,
                            pricevalue:_festivePrice,
                            countlist:_festivePriceCountList,
                        }

                        let checkIndex = this.festiveSelectedList.findIndex(object => {
                            return object.typeid === selectedInfo.typeid;
                        });

                        if (checkIndex != -1){
                            this.festiveSelectedList.splice(checkIndex, 1, selectedInfo);
                            // _tempInfo['isSelected'] = false;
                        }
                        else{
                            this.festiveSelectedList.push(selectedInfo)
                        }

                        _tempInfo['isSelected'] = true;
                        _tempInfo['selectedInfo'] = selectedInfo;
                        _tempInfo['selectedCountInfo'] = _festivePriceCountList;
                        _tempInfo['selectedCountAgeInfo'] = _festivePriceAgeList;


                        this.festiveInfo.splice(_index, 1, _tempInfo);
                        this.updateFestivePrice();

                        // window.scrollTo(0,0);
                        let element = this.$refs["add-values"];
                        window.scrollTo(0, element.offsetTop);

                        this.isFestiveSelected = true;
                        setTimeout(() => {
                            this.isFestiveSelected = false;
                        }, 1000);
                    }
                    
                    if (this.selectedEnhuncement == "HONEYMOON"){
                        const selectedInfo = {
                            type:"HONEYMOON",
                            typeid:this.honeymoonInfo[_index]._id,
                        }

                        let checkIndex = this.honeymoonSelectedList.findIndex(object => {
                            return object.typeid === selectedInfo.typeid;
                        });

                        let _tempInfo = this.honeymoonInfo[_index];
                        if (checkIndex != -1){
                            this.honeymoonSelectedList.splice(checkIndex, 1, selectedInfo);
                            // _tempInfo['isSelected'] = false;
                        }
                        else{
                            this.honeymoonSelectedList.push(selectedInfo)
                        }
                        _tempInfo['isSelected'] = true;
                        _tempInfo['selectedInfo'] = selectedInfo;

                        this.honeymoonInfo.splice(_index, 1, _tempInfo);

                        this.honeymoonInfoSelected = [];
                        this.honeymoonInfoSelected = this.honeymoonInfo.filter(obj => obj['isSelected']);

                        this.updateHoneymoonPrice();

                        // window.scrollTo(0,0);
                        let element = this.$refs["add-values"];
                        window.scrollTo(0, element.offsetTop);

                        this.isHoneymoonSelected = true;
                        setTimeout(() => {
                            this.isHoneymoonSelected = false;
                        }, 1000);
                    }
                }
            });

        },
        gotoBookingSummary(isPdf){
            this.guestArr = [];
            this.guestChildArr = [];
            const { checkoutGuestForm: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    if (values[`${GUESTINFOPART}guestfirstname`] != undefined && values[`${GUESTINFOPART}guestlastname`] != undefined){
                        (values[`${GUESTINFOPART}guestfirstname`]).forEach((item, index) => {
                            const obj = {
                                firstname: item,
                                lastname: values[`${GUESTINFOPART}guestlastname`][index],
                                sex: values[`${GUESTINFOPART}sex`][index],
                            }
                            this.guestArr.push(obj)
                        });
                        if (values[`${GUESTINFOPART}guestfirstnamechild`] != undefined){
                            (values[`${GUESTINFOPART}guestfirstnamechild`]).forEach((item, index) => {
                                const obj = {
                                    firstname: item,
                                    lastname: values[`${GUESTINFOPART}guestlastnamechild`][index],
                                    sex: values[`${GUESTINFOPART}sexchild`][index],
                                }
                                this.guestChildArr.push(obj)
                            });
                        }

                        var element = this.$refs["add-ons"];
                        // if (this.transferSelectedList.length <= 0 && this.transferInfo.length > 0)
                        // {
                        //     this.$message.error('Please select transfer');
                        //     var top = element.offsetTop;
                        //     window.scrollTo(0, top);
                        //     this.selectedEnhuncement = 'TRANSFER';
                        //     return;
                        // }
                        if (this.transferSelectedList.length > 0 && this.transferInfo.length > 0)
                        {
                            let adultcount = 0;
                            let childcount = 0;
                            let infantcount = 0;
                            for (let i = 0; i < this.transferSelectedList.length; i++){
                                adultcount += this.transferSelectedList[i].adultcount;
                                childcount += this.transferSelectedList[i].childcount;
                                infantcount += this.transferSelectedList[i].infantcount;
                            }
                            
                            var searchAdultCount = 0;
                            var searchChildCount = 0;
                            var searchChildAges = [];
                            var searchInfantCount = 0;
                            for (let i = 0; i < this.selectedRoomAllInfo.length; i++){
                                searchAdultCount += this.selectedRoomAllInfo[i].searchAdultCount;
                                // searchChildCount += this.selectedRoomAllInfo[i].searchChildCount;
                                searchChildAges = searchChildAges.concat(this.selectedRoomAllInfo[i].searchChildAge);
                            }
                            // searchAdultCount += searchChildAges.filter(obj => obj >= 12).length;
                            // const _adultAgeMin = this.selectedRoomAllInfo[i].adultAgeMin;
                            // const _childAgeMin = this.selectedRoomAllInfo[i].childAgeMin;

                            let adultMinAge = 12;
                            let childMinAge = 2;
                            if (this.transferInfo[0] != undefined){
                                if (this.transferInfo[0].selectedInfo != undefined && this.transferInfo[0].selectedInfo.adultAgeMin != undefined)
                                    adultMinAge = this.transferInfo[0].selectedInfo.adultAgeMin;
                                if (this.transferInfo[0].selectedInfo != undefined && this.transferInfo[0].selectedInfo.childAgeMin != undefined)
                                    childMinAge = this.transferInfo[0].selectedInfo.childAgeMin;
                            }

                            searchChildCount = searchChildAges.filter(obj => obj >= childMinAge).length;
                            searchInfantCount = searchChildAges.filter(obj => obj < childMinAge).length;

                            let _childAdultCount = searchChildAges.filter(obj => obj >= adultMinAge).length;
                            if (adultcount != searchAdultCount + _childAdultCount){
                                this.$message.error('Please select transfer for adult');
                                return;
                            }
                            if (childcount != searchChildCount - _childAdultCount){
                                this.$message.error('Please select transfer for child.');
                                return;
                            }
                            if (infantcount != searchInfantCount){
                                this.$message.error('Please select transfer for infant.');
                                return;
                            }
                        }
                        
                        if (this.festiveSelectedList.length <= 0 && this.festiveInfo.length > 0)
                        {
                            this.$message.error('Please select festive.');
                            window.scrollTo(0, element.offsetTop);
                            this.selectedEnhuncement = 'FESTIVE';
                            return;
                        }
                        if (this.festiveSelectedList.length > 0 && this.festiveInfo.length > 0)
                        {
                            // if (this.festiveSelectedList.length < this.festiveInfo.length){
                            //     this.$message.error('Please select all festive.');
                            //     return;
                            // }

                            
                            let searchAdultCount = 0;
                            // let searchChildCount = 0;
                            let searchChildAges = [];
                            for (let i = 0; i < this.selectedRoomAllInfo.length; i++){
                                searchAdultCount += this.selectedRoomAllInfo[i].searchAdultCount;
                                searchChildCount += this.selectedRoomAllInfo[i].searchChildCount;
                                if (this.selectedRoomAllInfo[i].searchChildCount > 0)
                                    searchChildAges = searchChildAges.concat(this.selectedRoomAllInfo[i].searchChildAge);
                            }

                            for (let i = 0; i < this.festiveInfo.length; i++){
                                let _countList = [];//this.festiveInfo[i].selectedCountInfo;
                                let priceInfoList = [];
                                if (this.festiveInfo[i].selectedCountInfo == undefined)
                                    continue;

                                for (let k = 0; k < this.festiveInfo[i].selectedCountInfo.length; k++){
                                    let aInfo = this.festiveInfo[i].selectedCountAgeInfo[k];
                                    let aInfoCount = this.festiveInfo[i].selectedCountInfo[k];

                                    let priceInfo = this.festiveInfo[i].price[k];
                                    if (aInfo.agefrom <= 25 && aInfo.ageto >= 25){
                                        _countList = [aInfoCount].concat(_countList);
                                        priceInfoList = [priceInfo].concat(priceInfoList);

                                    }
                                    else{
                                        _countList.push(aInfoCount);
                                        priceInfoList.push(priceInfo);
                                    }
                                }
                                
                                if (_countList.length > 0){
                                    let childToAdultCount = 0;
                                    for (let j = 0; j < searchChildAges.length; j++){
                                        const _curAge = searchChildAges[j];
                                        
                                        let _selectedCount = this.festiveInfo[i].price.filter(obj => obj.agefrom <= _curAge && obj.ageto >= _curAge && obj.ageto <= 15).length;
                                        
                                        if (_selectedCount <= 0){
                                            childToAdultCount += 1;//_selectedCount;
                                        }
                                    }
                                    
                                    if (_countList[0] != searchAdultCount + childToAdultCount){
                                        this.$message.error('Please select festive for adult');
                                        return;
                                    }
                                }
                                if (_countList.length > 1){
                                    for (let j = 1; j < _countList.length; j++){
                                        const fromAge = priceInfoList[j].agefrom;
                                        const toAge = priceInfoList[j].ageto;
                                        let _selectedCount = searchChildAges.filter(obj => obj >= fromAge && obj <= toAge && toAge <= 15).length;
                                        
                                        if (_selectedCount != _countList[j]){
                                            this.$message.error('Please select correctly festive for child');
                                            return;
                                        }
                                    }
                                    // let _tempCountList = 0;
                                    // for (let j = 1; j < _countList.length; j++){
                                    //     _tempCountList += _countList[i];
                                    // }
                                    // if (_tempCountList != searchChildCount){
                                    //     this.$message.error('Please select festive for child');
                                    //     return;
                                    // }
                                }
                            }
                        }

                        
                        if (this.mainPersonPhone == ''){
                            if(!this.validatePhone())
                                this.$message.error('Please input valid phone number.');
                            else
                                this.$message.error('Please input main person phone number.');
                            return;
                        }
                        
                        
                        if (this.mainpersonEmail == ''){
                            this.$message.error('Please input main person email.');
                            return;
                        }
                        // eslint-disable-next-line no-useless-escape
                        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
                        if (!regex.test(this.mainpersonEmail.toLowerCase())){
                            this.$message.error('Please check main person email.');
                            return
                        }
                        this.includeList = [];
                        if (this.transferSelectedList.length > 0){
                            this.includeList.push("Transfer");
                        }
                        if (this.mealplanSelectedList.length > 0){
                            this.includeList.push("Mealplan");
                        }
                        if (this.festiveSelectedList.length > 0){
                            this.includeList.push("Festive");
                        }
                        var honeymoonStr = '';
                        if (this.honeymoonInfoSelected.length > 0){
                            this.includeList.push("Honey Moon");
                            honeymoonStr = this.honeymoonInfoSelected.map(e => e.honeytitle).join(",");
                        }
                        // var test = true;
                        // if (test)
                        //     return;

                        // this.guests = '';
                        // if (this.searchChildCount > 0)
                        //     guests = this.searchAdultCount + 'adults and' + this.searchChildCount + 'children';
                        // else
                        //     guests = this.searchAdultCount + 'adults';


                        const requestInfo = {
                            hotelid: this.hotelId,
                            hotelname: this.selectedHotel.hotelname,
                            hotelimage1: this.selectedHotel.quoteinfomation != undefined ? this.selectedHotel.quoteinfomation.imageone : '',
                            hotelimage2: this.selectedHotel.quoteinfomation != undefined ? this.selectedHotel.quoteinfomation.imagetwo : '',
                            address: this.selectedHotel.hotelproperty.location,
                            fromDate: this.fromDate,
                            toDate: this.toDate,
                            paystripeid: '',
                            grossTotalPrice: this.grossTotalPrice,

                            bookingCode: this.bookingCode,
                            confirmationNo: '',

                            contrackctedPrice: this.contrackctedPrice,
                            sellingPrice: this.sellingPrice,
                            hoteltovue: this.hoteltovue,// + this.transferTotalPrice + this.mealplanTotalPrice + this.festiveTotalPrice,
                            sellingTotalPrice: this.sellingTotalPrice,
                            totalprice: this.totalprice,

                            transferTotalPrice: this.transferTotalPrice,
                            mealplanTotalPrice: this.mealplanTotalPrice,
                            festiveTotalPrice: this.festiveTotalPrice,
                            honeymoonInfo: honeymoonStr,
                            enhancePrice:this.transferTotalPrice + this.mealplanTotalPrice + this.festiveTotalPrice,

                            commission: this.permissionLevel >= 4 ? this.commission : '',

                            taxat: this.greenTax,
                            gstService: this.gstService,
                            extraAdult: this.extraAdult,
                            extraChild: this.extraChild,
                            // status: 0,  // 0:not paid, 1: Paid, 2:Approved, 3:Canceled
                            datalist: this.bookingReqData,
                            guestnames:this.guestArr,
                            guestchildnames:this.guestChildArr,
                            includeenhance:this.includeList,
                            totaladultcount:this.searchAdultCount,
                            totalchildcount:this.searchChildCount,
                            totalchildage:this.searchChildAge,
                            
                            inclusiontext : this.inclusionText,
                            termsconditions : this.termsconditions,
                            specialrequests : this.specialRequest,

                            transferTotalText: this.transferTotalText,
                            mealplanTotalText: this.mealplanTotalText,
                            festiveTotalText: this.festiveTotalText,
                            honeymoonTotalText: this.honeymoonTotalText,


                            roomCategory:this.roomName,
                            offer:this.offerName,
                            serviceAmenities:this.selectedHotel.quoteinfomation != undefined ? this.selectedHotel.quoteinfomation.services:'',
                            transferDetails:this.selectedHotel.quoteinfomation != undefined ? this.selectedHotel.quoteinfomation.transfer:'',
                            importantNote:this.selectedHotel.quoteinfomation != undefined ? this.selectedHotel.quoteinfomation.importantnote:'',
                            hotelPhoneNumber:this.selectedHotel.quoteinfomation != undefined ? this.selectedHotel.quoteinfomation.hotelphonenumber:'',
                            hotelEmail:this.selectedHotel.quoteinfomation != undefined ? this.selectedHotel.quoteinfomation.hotelemailaddress:'',
                            
                            agencyname:this.agencyName != undefined ? this.agencyName: '',
                            agentname:this.firstName != undefined && this.lastName != undefined && this.permissionLevel >= 4 ? this.firstName + ' ' + this.lastName : '',
                            username:this.firstName != undefined && this.lastName != undefined && this.permissionLevel < 4 ? this.firstName + ' ' + this.lastName : '',
                            guestList:this.guestList,
                            mainphone:this.mainPersonPhone,
                            mainemail:this.mainpersonEmail,

                            formpayment:'Stripe',
                            createdDate: new Date(),

                            bookingReqId:this.bookingReqId
                        };
                        if (isPdf == 0){
                            requestInfo['status'] = 0;
                            this.sendBookingRequest(requestInfo);
                        }
                        else if (isPdf == 1){
                            // this.createCard();
                            this.loadingCreateQuote = true;
                            this.createBookingPdf(requestInfo);
                        }
                        else if (isPdf == 2){
                            // this.createCard();
                            this.sendHoldRoom(requestInfo);
                        }
                    }
                    else{
                        this.$message.error('Please input Guest names.');
                        return;
                    }
                }
            });
        },
        sendBookingRequest:async function(requestInfo){
            
            if (this.bookingReqId != ''){
                let result = await api.getBookingRequestInfo(this.bookingReqId);
                if (result != undefined && result.status > 0 && result.status != 4 && result.status != 5 && result.status != 6){
                    this.$message.error('Please refresh and input again.');
                    return;
                }
                if (result != undefined){
                    let res = await api.bookingRequestUpdate(requestInfo, this.bookingReqId);
                    if (res != undefined && res.id != ''){
                        this.bookingReqId = res.id;
                        this.isShowCheckOut = true;
                    }
                    return;
                }
            }
            let res = await api.bookingRequest(requestInfo);
            if (res != undefined && res.id != ''){
                this.bookingReqId = res.id;
                this.isShowCheckOut = true;
            }
        },
        createBookingPdf:async function(requestInfo){
            
            if (this.bookingReqId != ''){
                let result = await api.getBookingRequestInfo(this.bookingReqId);
                if (result != undefined){
                    let res = await api.bookingRequestUpdate(requestInfo, this.bookingReqId);
                    if (res != undefined && res.id != ''){
                        this.bookingReqId = res.id;
                    }
                }
            }

            if (this.bookingReqId == ''){
                requestInfo['status'] = 5;
                let res = await api.bookingRequest(requestInfo);
                
                if (res != undefined && res.id != ''){
                    this.bookingReqId = res.id;
                }
            }
            
            // eslint-disable-next-line no-unused-vars
            let resPdf = await api.createBookingPdf(this.bookingReqId);
            this.loadingCreateQuote = false;
            
        },

        sendHoldRoom:async function(requestInfo){
            requestInfo['status'] = 6;
            if (this.bookingReqId != ''){
                let result = await api.getBookingRequestInfo(this.bookingReqId);
                if (result != undefined){
                    let res = await api.bookingRequestUpdate(requestInfo, this.bookingReqId);
                    if (res != undefined && res.id != ''){
                        this.bookingReqId = res.id;
                    }
                }
            }

            if (this.bookingReqId == ''){
                let res = await api.bookingRequest(requestInfo);
                
                if (res != undefined && res.id != ''){
                    this.bookingReqId = res.id;
                }
            }
            let res = await api.sendHoldRoom(requestInfo);
            
            if (res != undefined){
                // this.$message.success('Email sent for hold room!');
                this.visibleHoldSuccess = true;
            }
        },

        createCard:async function(cardNumber, expMonth, expYear, cardCvc, price){
            var requestInfo = {
                cardNumber: cardNumber,
                expMonth: expMonth,
                expYear: expYear,
                cardCvc: cardCvc,
                customerId: this.customerId
            }
            this.ErrorText = 'Something went wrong trying to process your booking. Please go back and try again or contact us at '
            if (this.customerId != undefined && this.customerId != ''){
                let res = await api.createCard(requestInfo);
                let cardId = res['id'];

                let sessionId = await this.createPaymentIntent(price, cardId);

                if (sessionId != undefined && cardId != undefined){
                    this.updateBookingPaying(sessionId, cardId, 1);//Paid status
                    // let paymentId = await this.confirmPayment(sessionId, cardId);
                    
                    // if (paymentId != undefined){
                    //     this.updateBookingStatus(paymentId, 1);//Paid status
                    //     this.isShowCheckOut = false;
                    // }
                    // else{
                    //     this.visibleError = true;
                    // }
                }
                else{
                    this.visibleError = true;
                }
            }
            else{
                this.visibleError = true;
            }

            this.paying = false;
        },
        createPaymentIntent:async function(price, cardId){
            var requestInfo = {
                customerId: this.customerId,
                price: price,
                currency: 'usd',
                description: 'Hotel Booking',
                cardId: cardId,
                emailReceipt: 'trueappleid@outlook.com'
            }
            let res = await api.createPaymentIntent(requestInfo);
            return res['id'];            
        },
        confirmPayment:async function(sessionId, cardId){
            var requestInfo = {
                sessionId: sessionId,
                cardId: cardId,
            }
            let res = await api.confirmPayment(requestInfo);
            return res.paymentIntent['id'];            
        },
        
        updateBookingPaying:async function(sessionId = '', cardId = '', status = 0){
            const _info = {
                sessionId: sessionId,
                cardId: cardId,
                status: status,
                paidDate: new Date(),
            }
            let res = await api.bookingRequestUpdatePay(_info, this.bookingReqId);
            if (res != undefined){
                this.$router.push({ path: '/bookingsummary/', query: {
                    bookingReqId: this.bookingReqId,
                }});
            }
        },

        updateBookingStatus:async function(paymentId = '', status = 0){
            const _info = {
                paystripeid: paymentId,
                status: status,
                paidDate: new Date(),
            }
            let res = await api.bookingRequestUpdatePay(_info, this.bookingReqId);
            if (res != undefined){
                this.$router.push({ path: '/bookingsummary/', query: {
                    bookingReqId: this.bookingReqId,
                }});
            }
        },
        updateTransferPrice(){
            let selectedList = this.transferInfo.filter(obj => obj.isSelected);
            this.transferTotalPrice = 0;
            this.transferTotalText = [];
            selectedList.forEach(element => {
                let adultPrice = 0;
                let childPrice = 0;
                let infantPrice = 0;
                if (element.price != undefined && element.price.peradultprice != undefined){
                    adultPrice = element.price.peradultprice;
                }
                if (element.price != undefined && element.price.perchildprice != undefined){
                    childPrice = element.price.perchildprice;
                }
                if (element.price != undefined && element.price.perinfantprice != undefined){
                    infantPrice = element.price.perinfantprice;
                }

                this.transferTotalPrice += adultPrice * element.selectedInfo.adultcount + childPrice * element.selectedInfo.childcount + infantPrice * element.selectedInfo.infantcount;
                
                this.transferTotalText.push(
                    {
                        title: element.transfertitle,
                        description: element.description
                    }
                )
            });
            this.updateGrossTotal();
        },
        updateMeanplanPrice(){
            let selectedList = this.mealplanInfo.filter(obj => obj.isSelected);
            this.mealplanTotalPrice = 0;
            this.mealplanTotalText = [];
            selectedList.forEach(element => {
                this.mealplanTotalPrice += element.selectedInfo.pricevalue;
                this.mealplanTotalText.push(
                    {
                        title: element.mealtitle,
                        description: element.description
                    }
                )
            });
            this.updateGrossTotal();
        },
        updateFestivePrice(){
            let selectedList = this.festiveInfo.filter(obj => obj.isSelected);
            this.festiveTotalPrice = 0;
            this.festiveTotalText = [];
            selectedList.forEach(element => {
                this.festiveTotalPrice += element.selectedInfo.pricevalue;
                this.festiveTotalText.push(
                    {
                        title: element.festivetitle,
                        description: element.description
                    }
                )
            });
            this.updateGrossTotal();
        },
        updateGrossTotal(){
            this.grossTotalPrice = this.subPrice + this.transferTotalPrice + this.mealplanTotalPrice + this.festiveTotalPrice + this.greenTax + this.gstService;
        },
        updateHoneymoonPrice(){
            let selectedList = this.honeymoonInfo.filter(obj => obj.isSelected);
            this.honeymoonTotalText = [];
            selectedList.forEach(element => {
                this.honeymoonTotalText.push(
                    {
                        title: element.honeytitle,
                        description: element.description
                    }
                )
            });
        },

        ErrorReturnClick(){
            this.visibleError = false;
            this.ErrorText = '';
        },
        ErrorCloseClick(){
            this.visibleError = false;
            this.isShowCheckOut = false;
            this.ErrorText = '';
        }
        
    },
};
</script>
<style scoped>
    .imageCheckout{
        display: block;
        width:100%;
        height:28vh;
        object-fit: cover;
    }
    .face:hover {
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }
    @keyframes shake {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-2px, 0, 0);
        }

        40%,
        60% {
            transform: translate3d(2px, 0, 0);
        }
    }
    
    .slideEnhanceImage{
        display: block;
        width:100%;
        height:35vh;
        object-fit: cover;
        cursor:pointer
    }
</style>