import { render, staticRenderFns } from "./HotelDetail.vue?vue&type=template&id=483d3e66&scoped=true"
import script from "./HotelDetail.vue?vue&type=script&lang=js"
export * from "./HotelDetail.vue?vue&type=script&lang=js"
import style0 from "./HotelDetail.vue?vue&type=style&index=0&id=483d3e66&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "483d3e66",
  null
  
)

export default component.exports