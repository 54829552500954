<template>
  <component :is="'div'">
    <!-- Layout Footer  :offset-top="top"-->
    <div id="reviewsio-carousel-widget" style="padding-left: 20px; padding-right: 20px;"></div>
    <a-layout-footer class="footerMainDiv">
      <div id="invtrflfloatbtn"></div>
      <div class="footerSubDiv">
        <div class="footerContentDiv">
          <!-- <div>
            <img
              class="logoImageFooter"
              onclick="location.href='/';"
              :src="logoImage"
            />
          </div> -->
          <div class="footerContentLinksDiv">
            <div class="quickLinks font-new">Quick Links</div>

            <div class="quickLinksDiv">
              <div
                v-for="(item, index) in linkList"
                :key="index"
                class="quickLinkCell font-new"
                @click="gotoQuickLink(item.url)"
              >
                {{ item.name }}
              </div>
            </div>

            <div class="footerIconBar">
              <div
                class="footerIconBarDiv"
                v-for="item in socialInfoList"
                :key="item.key"
              >
                <img
                  class="footerIconBarImg"
                  :src="
                    item.icon != '' && item.icon != undefined
                      ? `${item.icon}`
                      : ''
                  "
                  @click="gotoSocialPage(item.link)"
                />
              </div>
            </div>
          </div>
          <div class="font-new support">
            <div class="footerSignUpText font-new">Customer Support</div>
            <div class="flex w-full items-center mt-1 text-center">
              <div class="mr-2">
                <div class="flex justify-end">
                  <a
                    href="https://api.whatsapp.com/send/?phone=13477846476&text&type=phone_number&app_absent=0"
                    target="_blank"
                  >
                    <img
                      alt="whatsapp"
                      src="/icons/whatsapp.svg"
                      style="width: 28px; height: 28px; border-radius: 8px"
                    />
                  </a>
                </div>
                <div class="text-right mt-1">USA</div>
                <div class="text-right mt-1">AUSTRALIA</div>
              </div>
              <div>
                <div class="mt-5px text-center">WhatsApp</div>
                <div class="mt-17px text-center">+19144302223</div>
                <div class="mt-15px pt-1px text-center">+61286093948</div>
              </div>
            </div>
          </div>
          <div class="footerEmailSubscibeDiv">
            <div class="footerSignUpText font-new">
              Sign up to receive latest offers
            </div>
            <div class="footerEmailSubscibeParent">
              <a-input
                class="footerEmailInput font-new"
                v-model="subscribeEmail"
                placeholder="Email address*"
                :size="'large'"
              />
            </div>
            <div class="footerEmailSubscibeParent">
              <div
                v-if="!sendingSubscribe"
                class="footerEmailSubscibe font-new"
                @click="onSubscribe"
              >
                Subscribe
              </div>
              <div v-else class="footerEmailSubscibeDisable" style="">
                Subscribe
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="flex items-center">
          <div id="badge-ribbon"></div> -->
        <div class="w-full">
          <div class="footerEmailLineDiv"></div>
          <div class="footerDiv">
            <div class="footerLastText font-new">
              ©{{ currentYear }} Vue Collection. All rights reserved.
            </div>
            <div class="footerLastText font-new">
              <div style="margin-right: 5px">Created by</div>
              <a
                class="footer-link"
                href="https://vuelifestyle.com/"
                target="_blank"
                >Vuelifestyle.</a
              >
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </a-layout-footer>
  </component>
  <!-- / Main Sidebar -->
</template>

<script>
import { api } from "../../helpers/Helpers";
import { bus } from "../../main";
import moment from "moment";
import validator from "validator";
export default {
  props: {
    // Header fixed status.
    // navbarFixed: {
    // 	type: Boolean,
    // 	default: false,
    // },
  },
  data() {
    return {
      // Fixed header/sidebar-footer ( Affix component ) top offset.
      top: 0,
      nowYear: 2021,
      socialInfoList: [
        {
          _id: 0,
          icon: "",
          url: "",
        },
      ],
      subscribeEmail: "",
      logoImage: "/images/logo1.png",
      logoName: "MALDIVES",
      linkList: [
        {
          name: "About",
          url: "/about/",
        },
        {
          name: "Resorts",
          url: "/resorts/",
        },
        {
          name: "Contact",
          url: "/contact/",
        },
        {
          name: "Login",
          url: "login",
        },
        {
          name: "Sign Up",
          url: "signup",
        },
        {
          name: "Privacy Policy",
          url: "/privacypolicy/",
        },
        {
          name: "Terms & Conditions",
          url: "/termscondition/",
        },
        // {
        // 	name:'FAQ’s',
        // 	url:'',
        // },
      ],

      sendingSubscribe: false,

      currentYear: "",
    };
  },
  methods: {
    moment,
    resizeEventHandler() {},
    onSearch() {},
    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => resolve(script);
        script.onerror = () =>
          reject(new Error(`Script load error for ${src}`));
        document.head.appendChild(script);
      });
    },
    gotoTerms() {
      // this.$router.push({ path: '/termscondition/'})
      window.open("/termscondition", "_blank"); // "_blank"
    },
    gotoPolicy() {
      // this.$router.push({ path: '/privacypolicy/'})
      window.open("/privacypolicy", "_blank"); // "_blank"
    },
    gotoContact() {
      this.$router.push({ path: "/contact/" });
    },
    gotoSocialPage(_url) {
      if (_url != "" && _url != undefined) {
        window.open(_url, "_blank");
      }
    },

    getLogo: async function () {
      const res = await api.getLogoInfo();
      if (res.length > 0) {
        this.logoImage = `${res[0].logoimage}`;
        this.logoName = res[0].logoname;
      }
    },
    getSocialIcons: async function () {
      const res = await api.getSocialInfo();
      if (res.length > 0) {
        this.socialInfoList = res[0].datalist;
      }
    },
    onSubscribe: async function () {
      this.sendingSubscribe = true;
      if (
        this.subscribeEmail == "" ||
        !validator.isEmail(this.subscribeEmail)
      ) {
        this.$message.error("Please input valid email.");
        this.sendingSubscribe = false;
      } else {
        const subscribeInfo = {
          email: this.subscribeEmail,
          password: "",
          firstName: "Guest",
          lastName: "Guest",
          country: "United States of America",
          permissionLevel: 1,
        };
        const res = await api.subscribe(subscribeInfo);
        if (res != undefined && res != "") {
          this.$message.success("Subscribe success!");
          this.$emit("close");
        } else {
          this.$message.error("This email is already using by other");
        }
        this.sendingSubscribe = false;
      }
    },
    gotoQuickLink(_url) {
      if (_url == "login") {
        bus.$emit("showLogin", true);
      } else if (_url == "signup") {
        bus.$emit("showSignUp", true);
      } else {
        // this.$router.push({ path: _url});
        window.open(_url, "_blank"); // "_blank"
      }
    },
  },
  async mounted() {
    this.currentYear = moment(new Date()).utc().format("YYYY");
    try {
      await this.loadScript(
        "https://widget.reviews.io/carousel-inline-iframeless/dist.js?_t=2024090403"
      );

      this.$nextTick(() => {
        // Accessing the function via the window object
        if (typeof window.carouselInlineWidget === "function") {
          window.carouselInlineWidget("reviewsio-carousel-widget", {
            /*Your REVIEWS.io account ID:*/
            store: "vue-maldives",
            sku: "",
            lang: "en",
            carousel_type: "default",
            styles_carousel: "CarouselWidget--sideHeader",

            /*Widget settings:*/
            options: {
              general: {
                /*What reviews should the widget display? Available options: company, product, third_party. You can choose one type or multiple separated by comma.*/
                review_type: "company, product",
                /*Minimum number of reviews required for widget to be displayed*/
                min_reviews: "1",
                /*Maximum number of reviews to include in the carousel widget.*/
                max_reviews: "20",
                address_format: "CITY, COUNTRY",
                /*Carousel auto-scrolling speed. 3000 = 3 seconds. If you want to disable auto-scroll, set this value to false.*/
                enable_auto_scroll: 10000,
              },
              header: {
                /*Show overall rating stars*/
                enable_overall_stars: true,
                rating_decimal_places: 2,
              },
              reviews: {
                /*Show customer name*/
                enable_customer_name: true,
                /*Show customer location*/
                enable_customer_location: true,
                /*Show "verified review" badge*/
                enable_verified_badge: true,
                /*Show "verified subscriber" badge*/
                enable_subscriber_badge: true,
                /*Show "I recommend this product" badge (Only for product reviews)*/
                enable_recommends_badge: true,
                /*Show photos attached to reviews*/
                enable_photos: true,
                /*Show videos attached to reviews*/
                enable_videos: true,
                /*Show when review was written*/
                enable_review_date: false,
                /*Hide reviews written by the same customer (This may occur when customer reviews multiple products)*/
                disable_same_customer: true,
                /*Minimum star rating*/
                min_review_percent: 4,
                /*Show 3rd party review source*/
                third_party_source: true,
                /*Hide reviews without comments (still shows if review has a photo)*/
                hide_empty_reviews: true,
                /*Show product name*/
                enable_product_name: true,
                /*Show only reviews which have specific tags (multiple semicolon separated tags allowed i.e tag1;tag2)*/
                tags: "",
                /*Show branch, only one input*/
                branch: "",
                enable_branch_name: false,
              },
              popups: {
                /*Make review items clickable (When they are clicked, a popup appears with more information about a customer and review)*/
                enable_review_popups: true,
                /*Show "was this review helpful" buttons*/
                enable_helpful_buttons: true,
                /*Show how many times review was upvoted as helpful*/
                enable_helpful_count: false,
                /*Show share buttons*/
                enable_share_buttons: true,
              },
            },
            translations: {
              verified_customer: "Verified Customer",
            },
            styles: {
              /*Base font size is a reference size for all text elements. When base value gets changed, all TextHeading and TexBody elements get proportionally adjusted.*/
              "--base-font-size": "16px",
              "--base-maxwidth": "100%",

              /*Logo styles:*/
              "--reviewsio-logo-style": "var(--logo-normal)",

              /*Star styles:*/
              "--common-star-color": " #0E1311",
              "--common-star-disabled-color": " rgba(0,0,0,0.25)",
              "--medium-star-size": " 22px",
              "--small-star-size": "19px" /*Modal*/,
              "--x-small-star-size": "16px",
              "--x-small-star-display": "inline-flex",

              /*Header styles:*/
              "--header-order": "1",
              "--header-width": "160px",
              "--header-bg-start-color": "transparent",
              "--header-bg-end-color": "transparent",
              "--header-gradient-direction": "135deg",
              "--header-padding": "0.5em",
              "--header-border-width": "0px",
              "--header-border-color": "rgba(0,0,0,0.1)",
              "--header-border-radius": "0px",
              "--header-shadow-size": "0px",
              "--header-shadow-color": "rgba(0, 0, 0, 0.1)",

              /*Header content styles:*/
              "--header-star-color": "inherit",
              "--header-disabled-star-color": "inherit",
              "--header-heading-text-color": "inherit",
              "--header-heading-font-size": "inherit",
              "--header-heading-font-weight": "inherit",
              "--header-heading-line-height": "inherit",
              "--header-heading-text-transform": "inherit",
              "--header-subheading-text-color": "inherit",
              "--header-subheading-font-size": "inherit",
              "--header-subheading-font-weight": "inherit",
              "--header-subheading-line-height": "inherit",
              "--header-subheading-text-transform": "inherit",

              /*Review item styles:*/
              "--item-maximum-columns": "5" /*Must be 3 or larger*/,
              "--item-background-start-color": "transparent",
              "--item-background-end-color": "transparent",
              "--item-gradient-direction": "135deg",
              "--item-padding": "0.5em",
              "--item-border-width": "0px",
              "--item-border-color": "rgba(0,0,0,0.1)",
              "--item-border-radius": "0px",
              "--item-shadow-size": "0px",
              "--item-shadow-color": "rgba(0,0,0,0.1)",

              /*Heading styles:*/
              "--heading-text-color": " #0E1311",
              "--heading-text-font-weight": " 600",
              "--heading-text-font-family": " inherit",
              "--heading-text-line-height": " 1.4",
              "--heading-text-letter-spacing": "0",
              "--heading-text-transform": "none",

              /*Body text styles:*/
              "--body-text-color": " #0E1311",
              "--body-text-font-weight": "400",
              "--body-text-font-family": " inherit",
              "--body-text-line-height": " 1.4",
              "--body-text-letter-spacing": "0",
              "--body-text-transform": "none",

              /*Scroll button styles:*/
              "--scroll-button-icon-color": "#0E1311",
              "--scroll-button-icon-size": "24px",
              "--scroll-button-bg-color": "transparent",

              "--scroll-button-border-width": "0px",
              "--scroll-button-border-color": "rgba(0,0,0,0.1)",

              "--scroll-button-border-radius": "60px",
              "--scroll-button-shadow-size": "0px",
              "--scroll-button-shadow-color": "rgba(0,0,0,0.1)",
              "--scroll-button-horizontal-position": "0px",
              "--scroll-button-vertical-position": "0px",

              /*Badge styles:*/
              "--badge-icon-color": "#0E1311",
              "--badge-icon-font-size": "15px",
              "--badge-text-color": "#0E1311",
              "--badge-text-font-size": "inherit",
              "--badge-text-letter-spacing": "inherit",
              "--badge-text-transform": "inherit",

              /*Author styles:*/
              "--author-font-size": "inherit",
              "--author-font-weight": "inherit",
              "--author-text-transform": "inherit",

              /*Product photo or review photo styles:*/
              "--photo-video-thumbnail-size": "60px",
              "--photo-video-thumbnail-border-radius": "0px",

              /*Popup styles:*/
              "--popup-backdrop-color": "rgba(0,0,0,0.75)",
              "--popup-color": "#ffffff",
              "--popup-star-color": "inherit",
              "--popup-disabled-star-color": "inherit",
              "--popup-heading-text-color": "inherit",
              "--popup-body-text-color": "inherit",
              "--popup-badge-icon-color": "inherit",
              "--popup-badge-icon-font-size": "19px",
              "--popup-badge-text-color": "inherit",
              "--popup-badge-text-font-size": "14px",
              "--popup-border-width": "0px",
              "--popup-border-color": "rgba(0,0,0,0.1)",
              "--popup-border-radius": "0px",
              "--popup-shadow-size": "0px",
              "--popup-shadow-color": "rgba(0,0,0,0.1)",
              "--popup-icon-color": "#0E1311",

              /*Tooltip styles:*/
              "--tooltip-bg-color": "#0E1311",
              "--tooltip-text-color": "#ffffff",
            },
          });
        } else {
          console.error(
            "reviewsBadgeRibbon is not defined on the window object."
          );
        }
      });
    } catch (error) {
      console.error("Failed to load the script:", error);
    }
  },
  created() {
    var nowDate = new Date();
    this.nowYear = nowDate.getFullYear();
    this.getSocialIcons();
    // this.getLogo();
  },
  destroyed() {},
};

var ir =
  ir ||
  function () {
    (window.ir.q = window.ir.q || []).push(arguments);
  };
var invite_referrals = window.invite_referrals || {};
(function () {
  invite_referrals.auth = {
    bid_e: "5FEC791452A7F3677618B663E2BC62C7",
    bid: "67653",
    t: "420",
    email: "",
    userParams: { fname: "" },
  };
  invite_referrals.async = false;
  var script = document.createElement("script");
  script.src = "//cdn.invitereferrals.com/js/invite-referrals-1.0.js";

  var entry = document.getElementsByTagName("script")[0];
  entry.parentNode.insertBefore(script, entry);
})();
</script>
